import { useState } from "react";
import styles from "./formItemPreview.module.scss";
import trashIcon from "src/assets/trash.svg";
import { RotatingLines } from "react-loader-spinner";
import ActionModal from "src/_elements/actionModal/actionModal";
import ROUTES from "src/_utils/routes/routes";
import { Link } from "react-router-dom";
import PublishFormModal from "src/pages/formView/publishFormModal/publishFormModal";

const FormItemPreview: React.FC<any> = ({
  el,
  handleFormOpen,
  handleDeleteForm,
  handleUnpublishForm,
  getForms
}: any) => {
  const [isShowDeleteFormModal, setIsShowDeleteFormModal] = useState(false);
  const [isShowUnpublishFormModal, setIsShowUnpublishFormModal] =
    useState(false);
  const [isShowPublishModal, setIsShowPublishModal] = useState(false);

  const handleDeleteButtonClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsShowDeleteFormModal(true);
  };

  const handlePublishButtonClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsShowPublishModal(true);
  };

  const handleUnpublishButtonClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsShowUnpublishFormModal(true);
  };

  const FormItem: React.FC<any> = ({ handleFormOpenFoo }: any) => {
    return (
      <div
        className={`${styles.formButton} ${
          el.form.isPublished ? styles.formButtonPublushed : ""
        }`}
        onClick={() =>
          handleFormOpenFoo
            ? handleFormOpenFoo(el, el.formData.formsData.logo)
            : {}
        }
      >
        <button
          onClick={(e) => handleDeleteButtonClick(e)}
          className={styles.deleteFormButton}
        >
          <img height="20px" src={trashIcon} alt="delete" />
        </button>
        <div className={styles.formPreviewContent}>
          {el?.formData?.formsData.logo ? (
            <img
              width="80px"
              height="45px"
              src={el.formData.formsData.logo ?? ""}
              alt="logo"
            />
          ) : (
            <RotatingLines
              visible={true}
              width="30"
              strokeWidth="5"
              animationDuration="0.75"
              strokeColor="black"
            />
          )}
          <p>{el.form.name}</p>
        </div>
        {!el.form.isPublished && (
          <button
            className={styles.publishActionBtn}
            onClick={handlePublishButtonClick}
          >
            Publish
          </button>
        )}
        {el.form.isPublished && (
          <button
            className={`${styles.publishActionBtn} ${styles.unpublishBtn}`}
            onClick={handleUnpublishButtonClick}
          >
            Unpublish
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      {!el.form.isPublished ? (
        <Link to={`${ROUTES.FORMS}/${el.formData?.id}`}>
          <FormItem />
        </Link>
      ) : (
        <FormItem handleFormOpenFoo={handleFormOpen} />
      )}
      <ActionModal
        title={`Delete form ${el.form.name}?`}
        subtitle="This will permanently remove the form."
        isShowModal={isShowDeleteFormModal}
        setIsShowModal={setIsShowDeleteFormModal}
        handleAction={(e: any) => handleDeleteForm(e, el)}
        actionButtonText="Delete"
      />
      <ActionModal
        title={`Unpublish form ${el.form.name}?`}
        subtitle="This will unpublish your form, but you can publish it again later."
        isShowModal={isShowUnpublishFormModal}
        setIsShowModal={setIsShowUnpublishFormModal}
        handleAction={(e: any) => handleUnpublishForm(e, el)}
        actionButtonText="Unpublish"
      />
      <PublishFormModal
        isShowModal={isShowPublishModal}
        setIsShowModal={setIsShowPublishModal}
        formId={el.form?.id}
        getForms={getForms}
      />
    </>
  );
};

export default FormItemPreview;
