import styles from "./calendars.module.scss";

const Calendars: React.FC<any> = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
}: any) => {
  const handleClearDateFilters = () => {
    setStartDate("");
    setEndDate("");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.calendarItem}>
        <p>from:</p>
        <input
          onChange={(e) => setStartDate(e.target.value)}
          value={startDate}
          type="date"
        />
      </div>
      <div className={styles.calendarItem}>
        <p>to:</p>
        <input
          onChange={(e) => setEndDate(e.target.value)}
          value={endDate}
          type="date"
        />
      </div>
      <button onClick={handleClearDateFilters} className={styles.clearButton}>
        Clear date filters
      </button>
    </div>
  );
};

export default Calendars;
