import { Link } from "react-router-dom";
import styles from "./whoWeServeList.module.scss";
import ROUTES from "src/_utils/routes/routes";
import generalContracting from "src/assets/whoWeServe/generalContracting.png";
import painting from "src/assets/whoWeServe/painting.png";
import roofing from "src/assets/whoWeServe/roofing.png";
import solar from "src/assets/whoWeServe/solar.png";
import plumbing from "src/assets/whoWeServe/plumbing.png";
import electrical from "src/assets/whoWeServe/electrical.png";
import arrow from "src/assets/arrow.svg";

const WhoWeServeList = () => {
  return (
    <div className={styles.whoWeServeListWrapper}>
      <div className={styles.wrapper}>
        <p className={styles.title}>POPULAR TRADES</p>
        <div className={styles.tradesList}>
          <p>
            <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.GENERAL_CONTRACTING}`}>
              <img src={generalContracting} />
              General Contracting
            </Link>
          </p>
          <p>
            <img src={painting} />
            Painting
          </p>
          <p>
            <img src={roofing} /> Roofing
          </p>
          <p>
            <img src={solar} /> Solar
          </p>
          <p>
            <img src={plumbing} />
            Plumbing
          </p>
          <p>
            <img src={electrical} />
            Electrical
          </p>
        </div>
        <Link to={ROUTES.WHO_WE_SERVE} className={styles.exploreMore}>
          Explore All Trades <img src={arrow} />
        </Link>
      </div>
    </div>
  );
};

export default WhoWeServeList;
