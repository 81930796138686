import React, { useState } from "react";
import styles from "./chooseAnswerOptions.module.scss";
import { useSelector } from "react-redux";
import { IQuestion } from "../../interfaces/interfaces";
import AddElementButton from "src/_elements/addElementButton/addElementButton";
import AnswerOptionsModal from "./answerOptionsModal/answerOptionsModal";
import { truncateText } from "src/_utils/truncateText";

const ChooseAnswerOptions = () => {
  const questionsList = useSelector((state: any) => state.questionsList);

  const [showAnswerOptionsModal, setShowAnswerOptionsModal] = useState(false);
  const [currentElement, setCurrentElement] = useState<IQuestion | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleModalOpen = (el: IQuestion, index: number) => {
    setShowAnswerOptionsModal(true);
    setCurrentIndex(index);
    setCurrentElement(el);
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h3>Enter answer options (optional)</h3>
        <p>
          Select the questions for which you want to add answer options
          (optional)
        </p>
        <div className={styles.content}>
          {questionsList.map((el: IQuestion, index: number) => (
            <div key={index}>
              <AddElementButton onClick={() => handleModalOpen(el, index)}>
                {truncateText(el.question, 20)}
              </AddElementButton>
            </div>
          ))}
        </div>
      </div>
      <AnswerOptionsModal
        index={currentIndex}
        question={currentElement && currentElement.question}
        showAnswerOptionsModal={showAnswerOptionsModal}
        setShowAnswerOptionsModal={setShowAnswerOptionsModal}
      />
    </div>
  );
};

export default ChooseAnswerOptions;
