import { Link } from "react-router-dom";
import styles from "./footer.module.scss";
import companyLogo from "src/assets/homePage/companyLogo.png";
import facebook from "src/assets/homePage/facebook.svg";
import instagram from "src/assets/homePage/instagram.svg";
import ROUTES from "src/_utils/routes/routes";

const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.contentBlock}>
        <img
          width="170px"
          height="70px"
          src={companyLogo}
          alt="LeadQuiz logo"
        />
        <p>LeadQuiz helps you qualify leads, and collect their information.</p>
        <p>support@leadquiz.com</p>
      </div>
      <div className={styles.contentBlock}>
        <b>Navigation</b>
        <p>LeadQuiz helps you qualify leads, and collect their information.</p>
        <p>support@leadquiz.com</p>
      </div>
      <div className={`${styles.contentBlock} ${styles.socials}`}>
        <b>Socials</b>
        <a
          href="https://www.facebook.com/leadquiz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img width="12px" height="30px" src={facebook} alt="facebook" />
        </a>
        <a
          href="https://www.instagram.com/leadquiz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img width="24px" height="30px" src={instagram} alt="instagram" />
        </a>
      </div>
      <div className={styles.navWrap}>
        <ul className={styles.navigation}>
          <li>
            <Link to={ROUTES.HOME}>Home</Link>
          </li>
          <li>
            <Link to={ROUTES.HOW_IT_WORKS}>How it works</Link>
          </li>
          <li>
            <Link to={ROUTES.WHO_WE_SERVE}>Who we serve</Link>
          </li>
          <li>
            <Link to={ROUTES.PRICING}>Pricing</Link>
          </li>
        </ul>
        <Link to={ROUTES.PRICING}>
          <button className={styles.getStarted}>Get started</button>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
