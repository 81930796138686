import React, { useEffect, useState } from "react";
import styles from "./chooseConditions.module.scss";
import { useDispatch, useSelector } from "react-redux";
import AnswerOptionPreview from "../chooseAnswerOptions/answerOptionsModal/answerOptionPreview/answerOptionPreview";
import ConditionsModal from "./conditionsModal/conditionsModal";
import { setCurrentCreateFormPageAC } from "src/store/actions";
import { truncateText } from "src/_utils/truncateText";

const ChooseConditions = () => {
  const dispatch = useDispatch();

  const questionsList = useSelector((state: any) => state.questionsList);
  const isAnyAnswerOptions = useSelector(
    (state: any) => state.isAnyAnswerOptions
  );
  const currentIndex = useSelector((state: any) => state.currentCreateFormPage);

  const [showConditionsModal, setShowConditionsModal] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<
    number | null
  >(null);
  const [currentAnswerOptionIndex, setCurrentAnswerOptionIndex] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (!isAnyAnswerOptions) {
      dispatch(setCurrentCreateFormPageAC(currentIndex + 1));
    }
  }, [isAnyAnswerOptions]);

  const handleOpenModal = (
    questionIndex: number,
    answerOptionIndex: number
  ) => {
    setCurrentQuestionIndex(questionIndex);
    setCurrentAnswerOptionIndex(answerOptionIndex);
    setShowConditionsModal(true);
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h1>Select conditions (optional)</h1>
        <p>
          Select the answer options for which you want to add conditions for the
          following questions
        </p>
        <div className={styles.content}>
          {questionsList.map(
            (el: any, questionIndex: number) =>
              el.answerOptions && (
                <div key={questionIndex} className={styles.questionWrapper}>
                  <p className={styles.questionTitle}>
                    Question: <br />
                    <span>"{truncateText(el.question, 100)}"</span>
                  </p>
                  <div className={styles.answersPreview}>
                    {el.answerOptions.map(
                      (el: any, answerOptionIndex: number) => (
                        <AnswerOptionPreview
                          onClick={() =>
                            handleOpenModal(questionIndex, answerOptionIndex)
                          }
                          key={answerOptionIndex}
                          questionText={el.title}
                          questionIcon={el.icon}
                          disabled
                        />
                      )
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
      {currentQuestionIndex !== null && currentAnswerOptionIndex !== null && (
        <ConditionsModal
          showConditionsModal={showConditionsModal}
          setShowConditionsModal={setShowConditionsModal}
          questionIndex={currentQuestionIndex}
          answerOptionIndex={currentAnswerOptionIndex}
        />
      )}
    </div>
  );
};

export default ChooseConditions;
