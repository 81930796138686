import React, { useEffect, useState } from "react";
import styles from "./chooseLogoAndName.module.scss";
import FormInput from "src/_elements/formInput/formInput";
import FileUploader from "src/_elements/fileUploader/fileUploader";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompanyLogoAC,
  setCompanyNameAC,
  setContinueButtonDisabledAC,
} from "src/store/actions";

const ChooseLogoAndName = () => {
  const dispatch = useDispatch();

  const companyName = useSelector((state: any) => state.companyName);
  const companyLogo = useSelector((state: any) => state.companyLogo);

  const [fileName, setFileName] = useState<string | null>(null);
  const [isFileSizeError, setIsFileSizeError] = useState(false);
  const [isFileTypeError, setIsFileTypeError] = useState(false);

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        setFileName(null);
        setIsFileTypeError(true);
        dispatch(setCompanyLogoAC(null));
        return;
      }

      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB >= 3) {
        setFileName(null);
        setIsFileSizeError(true);
        dispatch(setCompanyLogoAC(null));
      } else {
        setIsFileTypeError(false);
        setIsFileSizeError(false);
        setFileName(file.name);
        dispatch(setCompanyLogoAC(file));
      }
    } else {
      setFileName(null);
    }
  };

  useEffect(() => {
    if (companyName !== "" && companyLogo) {
      dispatch(setContinueButtonDisabledAC(false));
    } else {
      dispatch(setContinueButtonDisabledAC(true));
    }
  }, [companyName, companyLogo]);

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h1>Enter your company name and select logo</h1>
        <div className={styles.content}>
          <FormInput
            placeholder="Company name"
            value={companyName}
            onChange={(e: any) => dispatch(setCompanyNameAC(e.target.value))}
            maxLength={20}
          />
          {isFileSizeError && (
            <p className={styles.errorMessage}>
              {"Maximum file size should be < 3Mb"}
            </p>
          )}
          {isFileTypeError && (
            <p className={styles.errorMessage}>
              {"Available formats: .jpeg, .png"}
            </p>
          )}
          <FileUploader
            handleFileSelect={handleImageSelect}
            fileName={fileName}
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseLogoAndName;
