import {
  ADD_ANSWER_OPTION,
  ADD_ANSWER_OPTION_TO_QUESTION,
  ADD_QUESTION_TO_ANSWER_OPTION,
  CLEAR_USER_ANSWERS,
  CURRENT_CREATE_FORM_PAGE,
  CURRENT_FORM_PAGE,
  EDIT_ICON_ANSWER_OPTION_TO_QUESTION,
  EDIT_TEXT_ANSWER_OPTION_TO_QUESTION,
  OBSERVE_ADDITIONAL_PURCHASE_ADDED,
  SET_COMPANY_LOGO,
  SET_COMPANY_NAME,
  SET_CONTINUE_BUTTON_DISABLED,
  SET_ENDING_PAGE_TEXT,
  SET_HOW_SHALL_WE_CONTACT_YOU_ERROR,
  SET_IS_ANY_ANSWER_OPTIONS,
  SET_IS_GOOGLE_AUTH_ERROR,
  OBSERVE_NEXT_PLAN_CHANGED,
  SET_QUESTIONS,
  SET_USER_ANSWERS,
  SET_USER_INFO,
  SET_USER_NAME,
  UPDATE_ANSWER_OPTION_ICON,
  UPDATE_ANSWER_OPTION_TEXT,
  UPDATE_CONDITIONAL_QUESTION,
  SET_IS_USER_PLAN_ACTIVE,
  SET_CURRENT_USER_PLAN,
  SET_IS_CURRENT_USER_PLAN_LOADING,
  SET_IS_SHOW_PRICING_MODAL,
  SET_IS_SHOW_LOGIN_MODAL,
  SET_IS_SHOW_REGISTER_MODAL,
  SET_HSWCY_OTHER_INFO,
} from "./actions";

const initialState = {
  isGoogleAuthError: false,
  companyName: "",
  companyLogo: null,
  currentCreateFormPage: 0,
  currentFormPage: 0,
  questionsList: [
    {
      question: "",
      answerOptions: [
        {
          title: "",
          icon: "",
          questionsArray: [{ question: "", answerOptions: null }],
        },
      ],
    },
  ],
  userAnswers: [{ question: "", answer: null }],
  isContinueButtonDisabled: true,
  isAnyAnswerOptions: false,
  endingPageText: "Thank you!",
  userInfo: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    date: "",
    time: "",
    address: "",
    zipOrPostal: "",
  },
  userName: "",
  howShallWeContactYou: {
    isGlobalError: false,
    isPhoneNumberError: false,
  },
  observeNextPlanChanged: false,
  observeAdditionalPurchaseAdded: false,
  isUserPlanActive: false,
  currentUserPlan: null,
  isCurrentUserPlanLoading: true,
  isShowPricingModal: false,
  isShowLoginModal: false,
  isShowRegisterModal: false,
  HSWCYOtherInfo: null,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_COMPANY_NAME:
      return {
        ...state,
        companyName: action.payload,
      };
    case SET_COMPANY_LOGO:
      return {
        ...state,
        companyLogo: action.payload,
      };
    case CURRENT_CREATE_FORM_PAGE:
      return {
        ...state,
        currentCreateFormPage: action.payload,
      };
    case CURRENT_FORM_PAGE:
      return {
        ...state,
        currentFormPage: action.payload,
      };
    case SET_QUESTIONS:
      return {
        ...state,
        questionsList: action.payload,
      };
    case SET_USER_ANSWERS:
      const updatedAnswers = state.userAnswers ? [...state.userAnswers] : [];
      updatedAnswers[action.payload.index] = action.payload.answer;
      return {
        ...state,
        userAnswers: updatedAnswers,
      };
    case CLEAR_USER_ANSWERS:
      return {
        ...state,
        userAnswers: [],
      };
    case ADD_ANSWER_OPTION:
      const { questionIndex, answerText } = action.payload;
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIdx) => {
          if (qIdx === questionIndex) {
            return {
              ...question,
              answerOptions: [
                ...(question.answerOptions || []),
                { title: answerText, questionsArray: [] },
              ],
            };
          }
          return question;
        }),
      };
    case ADD_ANSWER_OPTION_TO_QUESTION:
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIndex) =>
          qIndex === action.payload.questionIndex
            ? {
                ...question,
                answerOptions: question.answerOptions.map((option, aIndex) =>
                  aIndex === action.payload.answerOptionIndex
                    ? {
                        ...option,
                        questionsArray: option.questionsArray.map(
                          (qArrayItem, qArrayIndex) =>
                            qArrayIndex === action.payload.questionArrayIndex
                              ? {
                                  ...qArrayItem,
                                  answerOptions: [
                                    ...(qArrayItem.answerOptions || []),
                                    {
                                      title: "",
                                      icon: "",
                                      questionsArray: [],
                                    },
                                  ],
                                }
                              : qArrayItem
                        ),
                      }
                    : option
                ),
              }
            : question
        ),
      };
    case EDIT_TEXT_ANSWER_OPTION_TO_QUESTION:
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIndex) =>
          qIndex === action.payload.questionIndex
            ? {
                ...question,
                answerOptions: (question.answerOptions || []).map(
                  (option, aIndex) =>
                    aIndex === action.payload.answerOptionIndex
                      ? {
                          ...option,
                          questionsArray: (option.questionsArray || []).map(
                            (qArrayItem, qArrayIndex) =>
                              qArrayIndex === action.payload.questionArrayIndex
                                ? {
                                    ...qArrayItem,
                                    answerOptions: (
                                      qArrayItem.answerOptions || []
                                    ).map((ansOption: any, ansIndex) =>
                                      ansIndex === action.payload.optionIndex
                                        ? {
                                            ...ansOption,
                                            title: action.payload.title,
                                          }
                                        : ansOption
                                    ),
                                  }
                                : qArrayItem
                          ),
                        }
                      : option
                ),
              }
            : question
        ),
      };
    case EDIT_ICON_ANSWER_OPTION_TO_QUESTION:
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIndex) =>
          qIndex === action.payload.questionIndex
            ? {
                ...question,
                answerOptions: (question.answerOptions || []).map(
                  (option, aIndex) =>
                    aIndex === action.payload.answerOptionIndex
                      ? {
                          ...option,
                          questionsArray: (option.questionsArray || []).map(
                            (qArrayItem, qArrayIndex) =>
                              qArrayIndex === action.payload.questionArrayIndex
                                ? {
                                    ...qArrayItem,
                                    answerOptions: (
                                      qArrayItem.answerOptions || []
                                    ).map((ansOption: any, ansIndex) =>
                                      ansIndex === action.payload.optionIndex
                                        ? {
                                            ...ansOption,
                                            icon: action.payload.icon,
                                            iconFile: action.payload.iconFile,
                                          }
                                        : ansOption
                                    ),
                                  }
                                : qArrayItem
                          ),
                        }
                      : option
                ),
              }
            : question
        ),
      };
    case UPDATE_ANSWER_OPTION_TEXT:
      const { questionIndex: qIndex, optionIndex, newText } = action.payload;
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIdx) => {
          if (qIdx === qIndex) {
            return {
              ...question,
              answerOptions: question.answerOptions.map((option, oIdx) => {
                if (oIdx === optionIndex) {
                  return { ...option, title: newText };
                }
                return option;
              }),
            };
          }
          return question;
        }),
      };
    case UPDATE_ANSWER_OPTION_ICON:
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIdx) => {
          if (qIdx === action.payload.questionIndex) {
            return {
              ...question,
              answerOptions: question.answerOptions.map((option, oIdx) => {
                if (oIdx === action.payload.optionIndex) {
                  return {
                    ...option,
                    icon: action.payload.newIcon,
                    iconFile: action.payload.iconFile,
                  };
                }
                return option;
              }),
            };
          }
          return question;
        }),
      };
    case ADD_QUESTION_TO_ANSWER_OPTION:
      const {
        questionIndex: questionIdx,
        answerOptionIndex,
        questionText,
      } = action.payload;
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIdx) => {
          if (qIdx === questionIdx) {
            return {
              ...question,
              answerOptions: question.answerOptions.map((option, oIdx) => {
                if (
                  oIdx === answerOptionIndex &&
                  !action.payload.newQuestionIndex
                ) {
                  return {
                    ...option,
                    questionsArray: [...option.questionsArray, questionText],
                  };
                }
                return option;
              }),
            };
          }
          return question;
        }),
      };
    case UPDATE_CONDITIONAL_QUESTION:
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIndex) =>
          qIndex === action.payload.questionIndex
            ? {
                ...question,
                answerOptions: question.answerOptions.map((option, aIndex) =>
                  aIndex === action.payload.answerOptionIndex
                    ? {
                        ...option,
                        questionsArray: option.questionsArray.map(
                          (qArrayItem, newQIndex) =>
                            newQIndex === action.payload.newQuestionIndex
                              ? {
                                  question: action.payload.questionText,
                                  answerOptions: null,
                                }
                              : qArrayItem
                        ),
                      }
                    : option
                ),
              }
            : question
        ),
      };
    case SET_CONTINUE_BUTTON_DISABLED:
      return {
        ...state,
        isContinueButtonDisabled: action.payload,
      };
    case SET_IS_ANY_ANSWER_OPTIONS:
      return {
        ...state,
        isAnyAnswerOptions: action.payload,
      };
    case SET_IS_GOOGLE_AUTH_ERROR:
      return {
        ...state,
        isGoogleAuthError: action.payload,
      };
    case SET_ENDING_PAGE_TEXT:
      return {
        ...state,
        endingPageText: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case SET_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };
    case SET_HOW_SHALL_WE_CONTACT_YOU_ERROR:
      return {
        ...state,
        howShallWeContactYou: {
          isGlobalError: action.payload,
        },
      };
    case OBSERVE_NEXT_PLAN_CHANGED:
      return {
        ...state,
        observeNextPlanChanged: action.payload,
      };
    case OBSERVE_ADDITIONAL_PURCHASE_ADDED:
      return {
        ...state,
        observeAdditionalPurchaseAdded: action.payload,
      };
    case SET_IS_USER_PLAN_ACTIVE:
      return {
        ...state,
        isUserPlanActive: action.payload,
      };
    case SET_CURRENT_USER_PLAN:
      return {
        ...state,
        currentUserPlan: action.payload,
      };
    case SET_IS_CURRENT_USER_PLAN_LOADING:
      return {
        ...state,
        isCurrentUserPlanLoading: action.payload,
      };
    case SET_IS_SHOW_PRICING_MODAL:
      return {
        ...state,
        isShowPricingModal: action.payload,
      };
    case SET_IS_SHOW_LOGIN_MODAL:
      return {
        ...state,
        isShowLoginModal: action.payload,
      };
    case SET_IS_SHOW_REGISTER_MODAL:
      return {
        ...state,
        isShowRegisterModal: action.payload,
      };
    case SET_HSWCY_OTHER_INFO:
      return {
        ...state,
        HSWCYOtherInfo: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
