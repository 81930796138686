import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInput from "src/_elements/formInput/formInput";
import AnswerItemButtonWithImage from "src/pages/formBuilder/buttonsWithImageEdit/answerItemButtonWithImage";
import { setCurrentFormPageAC, setUserAnswersAC } from "src/store/actions";
import styles from "./questionPage.module.scss";

const QuestionPage: React.FC<any> = ({
  element,
  isVisible,
  setCurrentQuestionsList,
  answerIndex,
  setAnswerIndex,
}: any) => {
  const dispatch = useDispatch();
  const currentIndex = useSelector((state: any) => state.currentFormPage);

  const containerRef = useRef<HTMLDivElement>(null);

  if (!isVisible) {
    return null;
  }

  const handleAnswer = (e: React.ChangeEvent<HTMLInputElement>, el: any) => {
    if (el?.questionsArray.length > 0) {
      setCurrentQuestionsList(el.questionsArray);
      dispatch(setCurrentFormPageAC(0));
      setAnswerIndex((prevState: number) => ++prevState);
    } else if (element.answerOptions?.length > 0) {
      dispatch(setCurrentFormPageAC(currentIndex + 1));
      setAnswerIndex((prevState: number) => ++prevState);
    }
    dispatch(
      setUserAnswersAC(
        { question: element.question, answer: e.target.value },
        answerIndex
      )
    );
  };

  return (
    <div className={styles.questionsWrapper}>
      <p className={styles.questionTitle}>{element.question}</p>
      {(element.answerOptions?.length === 0 || !element.answerOptions) && (
        <FormInput onChange={handleAnswer} placeholder="Type your answer here" />
      )}
      <div ref={containerRef} className={styles.answerOptions}>
        {element.answerOptions &&
          element.answerOptions.map((el: any, index: number) => (
            <AnswerItemButtonWithImage
              key={index}
              question={el.title}
              icon={el.icon}
              handleOptionChange={(e: any) => handleAnswer(e, el)}
            />
          ))}
      </div>
    </div>
  );
};

export default QuestionPage;
