import React from "react";
import styles from "./textArea.module.scss";
import { ITextArea } from "../interfaces/interfaces";

const TextArea: React.FC<ITextArea> = ({
  placeholder,
  value,
  onChange,
}: ITextArea) => {
  return (
    <div className={styles.wrapper}>
      <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextArea;
