import React from "react";
import styles from "./formInput.module.scss";
import { IInput } from "../interfaces/interfaces";

const FormInput: React.FC<IInput> = ({
  placeholder,
  value,
  onChange,
  type = "text",
  maxLength,
}: IInput) => {
  return (
    <div className={styles.wrapper}>
      <input
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    </div>
  );
};

export default FormInput;
