import { IPlan } from "src/pages/interfaces/interfaces";
import styles from "./plan.module.scss";
import editIcon from "src/assets/edit.svg";
import { useEffect, useState } from "react";
import {
  deleteAdditionalPurchase,
  getAllPlanAdditionalPurchaseApi,
} from "src/_utils/api/api";
import { useSelector } from "react-redux";

const Plan: React.FC<IPlan> = ({
  planData,
  setIsShowPlanEditModal,
  setSelectedPlanData,
  setIsShowAddSubmissionsModal,
}: IPlan) => {
  const observeAdditionalPurchaseAdded = useSelector(
    (state: any) => state.observeAdditionalPurchaseAdded
  );

  const {
    name,
    stripePrices,
    maxDomains,
    maxSubmissionsPerMonth,
    needToContactSales,
    active,
  } = planData;

  const [additionalPurchases, setAdditionalPurchases] = useState([]);

  useEffect(() => {
    getAllPlanAdditionalPurchaseApi(planData.id).then((res) => {
      setAdditionalPurchases(res.data.data);
    });
  }, [observeAdditionalPurchaseAdded]);

  const result = additionalPurchases.filter(
    (el: any) => el.planId === planData.id
  );

  const additionalPurhasesList = result.map((el: any, index: number) => (
    <div key={index} className={styles.purchaseItem}>
      <p>{el.name}</p>
      {/* <button onClick={() => deleteAdditionalPurchase(el.id)}>x</button> */}
    </div>
  ));

  const handleOpenAddSubmissionsModal = () => {
    setSelectedPlanData(planData);
    setIsShowAddSubmissionsModal(true);
  };

  const handleOpenEditPlanModal = () => {
    setSelectedPlanData(planData);
    setIsShowPlanEditModal(true);
  };

  return (
    <div className={styles.planWrapper}>
      <ul>
        <li>
          Plan name: <span className={styles.planName}>{name}</span>
        </li>
        <li>Max domains: {maxDomains}</li>
        <li>Price per month: {stripePrices?.pricePerMonth.unit_amount}</li>
        <li>Price per year: {stripePrices?.pricePerYear.unit_amount}</li>
        <li>Max submissions per month: {maxSubmissionsPerMonth}</li>
        <li>Need to contact sales: {needToContactSales ? "yes" : "no"}</li>
        <li>Active: {active ? "yes" : "no"}</li>
        {additionalPurhasesList[0] && (
          <p className={styles.additionalPurchases}>Additional purchases:</p>
        )}
        <li>{additionalPurhasesList}</li>
      </ul>
      <div className={styles.buttonsWrapper}>
        <button
          onClick={handleOpenAddSubmissionsModal}
          className={styles.addSubmissions}
        >
          +
        </button>
        <button onClick={handleOpenEditPlanModal} className={styles.editButton}>
          <img src={editIcon} alt="edit" />
        </button>
      </div>
    </div>
  );
};

export default Plan;
