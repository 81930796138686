import styles from "./leadItem.module.scss";

const LeadItem: React.FC<any> = ({
  formattedDate,
  username,
  questionsAndAnswers,
}: any) => {
  const questionsAndAnswersList = Object.entries(questionsAndAnswers.data).map(
    ([question, answer]) => (
      <div key={question} className={styles.questionAnswerItem}>
        <strong>{question}:</strong>
        <p>{(answer as string) === "" ? "-" : (answer as string)}</p>
      </div>
    )
  );

  return (
    <div className={styles.wrapper}>
      <p>{formattedDate}</p>
      <p>{username}</p>
      <div>{questionsAndAnswersList}</div>
    </div>
  );
};

export default LeadItem;
