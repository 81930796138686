import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./resetPassword.module.scss";
import { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import ROUTES from "src/_utils/routes/routes";
import { resetPasswordApi } from "src/_utils/api/api";
import { IResetPasswordPayload } from "src/_utils/interfaces/interfaces";
import { Helmet } from "react-helmet";
import companyLogo from "src/assets/homePage/companyLogo.png";
import {
  validatePasswordDigit,
  validatePasswordLength,
  validatePasswordSpecialSymbol,
  validatePasswordUpperCase,
} from "src/_utils/passwordValidator";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [userPassword, setUserPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [userPasswordError, setUserPasswordError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  const changePassword = () => {
    setIsLoading(true);

    if (!validatePasswordSpecialSymbol(userPassword)) {
      setUserPasswordError(true);
      setErrorMessage("Password should contain at least 1 special character");
      setIsLoading(false);
    } else if (!validatePasswordUpperCase(userPassword)) {
      setUserPasswordError(true);
      setErrorMessage("Password should contain at least 1 uppercase character");
      setIsLoading(false);
    } else if (!validatePasswordDigit(userPassword)) {
      setUserPasswordError(true);
      setErrorMessage("Password should contain at least 1 numeric character");
      setIsLoading(false);
    } else if (!validatePasswordLength(userPassword)) {
      setUserPasswordError(true);
      setErrorMessage("Password length must be at least 5 characters long");
      setIsLoading(false);
    } else {
      setErrorMessage("");
      setUserPasswordError(false);

      const payload: IResetPasswordPayload = {
        token: token,
        newPassword: userPassword,
      };

      resetPasswordApi(payload)
        .then(() => {
          setIsLoading(false);
          navigate(ROUTES.LOGIN);
        })
        .catch((error) => {
          setIsLoading(false);

          console.error("Error fetching data:", error);

          if (userPassword === "") {
            setUserPasswordError(true);
            setErrorMessage("Please fill in password field");
          }
        });
    }
  };

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Reset password</title>
      </Helmet>
      <div className={styles.wrapper}>
        <Link to={ROUTES.HOME}>
          <img
            width="170px"
            height="68px"
            src={companyLogo}
            alt="company logo"
          />
        </Link>
        <div className={styles.inputsWrapper}>
          <input
            className={userPasswordError ? styles.errorInput : ""}
            value={userPassword}
            onChange={(e) => setUserPassword(e.currentTarget.value)}
            type="password"
            placeholder="Password"
          />
          {errorMessage && (
            <p className={styles.errorMessage}>{errorMessage}</p>
          )}
          <button onClick={changePassword}>
            {!isLoading ? (
              "Change password"
            ) : (
              <RotatingLines
                visible={true}
                width="30"
                strokeWidth="5"
                animationDuration="0.75"
                strokeColor="white"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
