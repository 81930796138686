import Plan from "../plan/plan";
import styles from "./personalPlans.module.scss";

const PersonalPlans: React.FC<any> = ({
  handleAddNewPlan,
  setIsPersonalPlan,
  personalPlans,
  setIsShowAddSubmissionsModal,
  setIsShowPlanEditModal,
  setSelectedPlanData,
}: any) => {
  const handleAddPersonalPlan = () => {
    setIsPersonalPlan(true);
    handleAddNewPlan();
  };

  return (
    <div className={styles.wrapper}>
      <button className={styles.button} onClick={handleAddPersonalPlan}>
        <p>Add new personal plan</p>
      </button>
      {personalPlans &&
        personalPlans.map((item: any, index: number) => (
          <Plan
            key={index}
            planData={item}
            setIsShowAddSubmissionsModal={setIsShowAddSubmissionsModal}
            setIsShowPlanEditModal={setIsShowPlanEditModal}
            setSelectedPlanData={setSelectedPlanData}
          />
        ))}
    </div>
  );
};

export default PersonalPlans;
