import { useEffect, useState } from "react";
import styles from "./paymentPlan.module.scss";
import { loadStripe } from "@stripe/stripe-js";
import {
  apiRoutes,
  cancelSubscriptionAtEndApi,
  changePlanApi,
  getSubscriptionScheduleApi,
  reactivatePlatApi,
} from "src/_utils/api/api";
import pointIcon from "src/assets/homePage/point.svg";
import { RotatingLines } from "react-loader-spinner";
import { observeNextPlanChangedAC } from "src/store/actions";
import { useDispatch, useSelector } from "react-redux";
import ActionModal from "src/_elements/actionModal/actionModal";

const PaymentPlan: React.FC<any> = ({
  title,
  price,
  list,
  maxDomains,
  maxSubmissionsPerMonth,
  id,
  priceId,
  isBought,
  isNextPlan,
  endBehavior,
  isPlanChanged,
  isCurrentPlanChanged,
  setIsCurrentPlanChanged,
  totalAdditionalSubmissionsCount,
  additionalPurchases,
  isNeedToContactSales,
  isNeedUpdatePlan,
  isCurrentPlanAvailable,
  periodLabel,
  isPersonalPlan,
  isPlanPermanent,
}: any) => {
  const [listArray, setListArray] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangeNextPlanLoading, setIsChangeNextPlanLoading] = useState(false);
  const [isShowUnsubscribeModal, setIsShowUnsubscribeModal] = useState(false);
  const [isShowReactivateModal, setIsShowReactivateModal] = useState(false);
  const [isShowChangePlanModal, setIsShowChangePlanModal] = useState(false);
  const [currentEndBehavior, setCurrentEndBehavior] = useState(endBehavior);

  const dispatch = useDispatch();

  const observeNextPlanChanged = useSelector(
    (state: any) => state.observeNextPlanChanged
  );

  useEffect(() => {
    setCurrentEndBehavior(endBehavior);
  }, [endBehavior]);

  useEffect(() => {
    setIsCurrentPlanChanged && setIsCurrentPlanChanged(isPlanChanged);
  }, [isPlanChanged]);

  useEffect(() => {
    if (list) {
      setListArray(
        list &&
          list.map((el: string, index: number) => (
            <li key={index}>
              <img
                style={{ filter: `invert(${title === "Pro" ? 1 : 0})` }}
                src={pointIcon}
                alt="point"
              />
              <p>{el}</p>
            </li>
          ))
      );
    } else {
      const list = [
        `Unlimited Questions`,
        `${maxDomains} Custom Domain`,
        `${
          totalAdditionalSubmissionsCount
            ? maxSubmissionsPerMonth + totalAdditionalSubmissionsCount
            : maxSubmissionsPerMonth
        } submissions/month`,
      ];

      setListArray(
        list &&
          list.map((el: string, index: number) => (
            <li key={index}>
              <img
                style={{ filter: `invert(${title === "Pro" ? 1 : 0})` }}
                src={pointIcon}
                alt="point"
              />
              <p>{el}</p>
            </li>
          ))
      );
    }
  }, [maxDomains, maxSubmissionsPerMonth]);

  const handleGetPlan = async () => {
    const token = localStorage.getItem("token");

    const stripePromise = loadStripe(
      process.env.REACT_APP_PUBLISHABLE_KEY as string
    );

    const stripe = await stripePromise;

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiRoutes.stripeCreateSession}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ planId: id, priceId }),
      }
    );

    const data = await response.json();

    const sessionId = data.data.sessionId;

    //@ts-ignore
    const { error } = await stripe?.redirectToCheckout({
      sessionId,
    });
  };

  const handleGetAdditionalPurchase = async (stripePriceId: string) => {
    const token = localStorage.getItem("token");

    const stripePromise = loadStripe(
      process.env.REACT_APP_PUBLISHABLE_KEY as string
    );

    const stripe = await stripePromise;

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${apiRoutes.stripeCreatePurchaseAdditionalSession}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          stripePriceId,
        }),
      }
    );

    const data = await response.json();

    const sessionId = data.data.sessionId;

    //@ts-ignore
    const { error } = await stripe?.redirectToCheckout({
      sessionId,
    });
  };

  const handleChangePlan = () => {
    setIsShowChangePlanModal(false);
    setIsChangeNextPlanLoading(true);
    changePlanApi(priceId)
      .then(() => {
        dispatch(observeNextPlanChangedAC(!observeNextPlanChanged));
        setIsChangeNextPlanLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const showPrice = price && price.toString().padStart(3, "0");

  const handleUnsubscribe = () => {
    setIsShowUnsubscribeModal(false);
    setIsLoading(true);
    cancelSubscriptionAtEndApi().then(() => {
      getSubscriptionScheduleApi().then((res) => {
        setCurrentEndBehavior(res.data.data[0].end_behavior);
        setIsCurrentPlanChanged(res.data.data[0].phases[1]);
        setIsLoading(false);
      });
    });
  };

  const handleReactivatePlan = () => {
    setIsShowReactivateModal(false);
    setIsLoading(true);
    reactivatePlatApi().then(() => {
      getSubscriptionScheduleApi().then((res) => {
        setCurrentEndBehavior(res.data.data[0].end_behavior);
        setIsCurrentPlanChanged(res.data.data[0].phases[1]);
        setIsLoading(false);
      });
    });
  };

  const additionalPurchasesList =
    additionalPurchases &&
    additionalPurchases.map((el: any, index: number) => (
      <button
        key={index}
        onClick={() => handleGetAdditionalPurchase(el.stripePriceId)}
      >
        {el.name}
      </button>
    ));

  return (
    <>
      <div
        className={`${styles.wrapper} ${
          title !== "Pro" ? styles.lightPlan : styles.darkPlan
        }`}
      >
        <p>
          {title}
          {isPersonalPlan && (
            <span className={styles.personalPlanLabel}> (Personal offer)</span>
          )}
        </p>
        {isNeedUpdatePlan && (
          <div className={styles.alert}>
            <p>
              price has changed, please re-subscribe to current or another plan
              from the next period
            </p>
          </div>
        )}
        {(price || price === 0) && !isNeedToContactSales ? (
          <p>
            <span className={styles.price}>
              {price === 0
                ? "$0.00"
                : `$${showPrice.slice(0, -2)}.${showPrice.slice(-2)}`}
            </span>
            /{periodLabel}
          </p>
        ) : isNeedUpdatePlan ? (
          <></>
        ) : (
          <p className={styles.contactUs}>Contact us</p>
        )}
        <div className={styles.buttonWrapper}>
          {!isNextPlan &&
            !isBought &&
            !isNeedToContactSales &&
            !isCurrentPlanAvailable &&
            title !== "Enterprise" && (
              <button onClick={handleGetPlan}>
                Get {title.toLowerCase()} plan
              </button>
            )}
          {!isNextPlan && !price && isNeedToContactSales && (
            <button>Contact sales</button>
          )}
          {isBought &&
            !isCurrentPlanChanged &&
            !isPlanPermanent &&
            currentEndBehavior !== "cancel" && (
              <button onClick={() => setIsShowUnsubscribeModal(true)}>
                {!isLoading ? (
                  "Unsubscribe"
                ) : (
                  <RotatingLines
                    visible={true}
                    width="30"
                    strokeWidth="5"
                    animationDuration="0.75"
                    strokeColor="white"
                  />
                )}
              </button>
            )}
          {isBought &&
            (isCurrentPlanChanged || currentEndBehavior === "cancel") &&
            !isNeedUpdatePlan && (
              <button onClick={() => setIsShowReactivateModal(true)}>
                {!isLoading ? (
                  "Reactivate"
                ) : (
                  <RotatingLines
                    visible={true}
                    width="30"
                    strokeWidth="5"
                    animationDuration="0.75"
                    strokeColor="white"
                  />
                )}
              </button>
            )}
          {!isNextPlan &&
            !isBought &&
            !isNeedToContactSales &&
            isCurrentPlanAvailable && (
              <button onClick={() => setIsShowChangePlanModal(true)}>
                {!isChangeNextPlanLoading ? (
                  `Change to ${title.toLowerCase()}`
                ) : (
                  <RotatingLines
                    visible={true}
                    width="30"
                    strokeWidth="5"
                    animationDuration="0.75"
                    strokeColor="white"
                  />
                )}
              </button>
            )}
        </div>
        <ul>{listArray}</ul>
        {isBought && additionalPurchasesList[0] && (
          <>
            <p className={styles.additionalPurchasesTitle}>
              Additional purchases:{" "}
            </p>
            <div className={styles.additionalPurchasesWrapper}>
              {additionalPurchasesList}
            </div>
          </>
        )}
      </div>
      <ActionModal
        title={`Unsubscribe from plan ${title}?`}
        subtitle="This will unsubscribe you from your current plan, but it will remain
          in effect until your next payment date, after which the plan will
          cease and no money will be charged."
        isShowModal={isShowUnsubscribeModal}
        setIsShowModal={setIsShowUnsubscribeModal}
        handleAction={handleUnsubscribe}
        actionButtonText="Unsubscribe"
      />
      <ActionModal
        title={`Reactivate plan ${title}?`}
        subtitle="This will restore your plan subscription."
        isShowModal={isShowReactivateModal}
        setIsShowModal={setIsShowReactivateModal}
        handleAction={handleReactivatePlan}
        actionButtonText="Reactivate"
      />
      <ActionModal
        title={`Change plan to ${title}?`}
        subtitle="When your current plan ends, your selected plan will take effect. Are you sure you want to change your plan?"
        isShowModal={isShowChangePlanModal}
        setIsShowModal={setIsShowChangePlanModal}
        handleAction={handleChangePlan}
        actionButtonText="Change plan"
      />
    </>
  );
};

export default PaymentPlan;
