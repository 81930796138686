export const validatePasswordSpecialSymbol = (password: string) => {
  const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

  return specialCharacterRegex.test(password);
};

export const validatePasswordUpperCase = (password: string) => {
  const uppercaseRegex = /[A-Z]/;

  return uppercaseRegex.test(password);
};

export const validatePasswordDigit = (password: string) => {
  const digitRegex = /\d/;

  return digitRegex.test(password);
};

export const validatePasswordLength = (password: string) => {
  return password.length > 4;
};
