import { useEffect, useState } from "react";
import Header from "../homePage/header/header";
import styles from "./forms.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyLogoAC, setQuestionsAC } from "src/store/actions";
import { RotatingLines } from "react-loader-spinner";
import ROUTES from "src/_utils/routes/routes";
import {
  deleteFormApi,
  getFormsApi,
  unpublishFormApi,
} from "src/_utils/api/api";
import { Helmet } from "react-helmet";
import FormItemPreview from "./formItemPreview/formItemPreview";
import FormSubmissionEmailIntegration from "../settingPage/formSubmissionEmailIntegration/formSubmissionEmailIntegration";

const Forms = () => {
  const [formsList, setFormsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [zapierApiKeyCopyStatus, setZapierApiKeyCopyStatus] = useState("");

  const token = localStorage.getItem("token");

  const isUserPlanActive = useSelector((state: any) => state.isUserPlanActive);
  const isCurrentUserPlanLoading = useSelector(
    (state: any) => state.isCurrentUserPlanLoading
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormOpen = (el: any, logo: string) => {
    dispatch(setQuestionsAC(el.formData.formsData));
    dispatch(setCompanyLogoAC(logo));

    const subdomain = el.cname.subDomain;
    const newUrl = `https://${subdomain}.${process.env.REACT_APP_DOMAIN}`;

    window.location.href = newUrl;
  };

  const getForms = () => {
    getFormsApi()
      .then((res) => {
        setFormsList(res.data.data.reverse());
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getForms();
  }, []);

  useEffect(() => {
    if (!token || !isCurrentUserPlanLoading) {
      if (!isUserPlanActive) {
        navigate(ROUTES.HOME);
      }
    }
  }, [token, isUserPlanActive, isCurrentUserPlanLoading]);

  useEffect(() => {
    if (zapierApiKeyCopyStatus !== "") {
      setTimeout(() => {
        setZapierApiKeyCopyStatus("");
      }, 2000);
    }
  }, [zapierApiKeyCopyStatus]);

  const handleDeleteForm = (e: any, el: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsLoading(true);

    deleteFormApi(el.form?.id)
      .then(() => {
        getForms();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleUnpublishForm = (e: any, el: any) => {
    e.stopPropagation();
    e.preventDefault();

    setIsLoading(true);

    const payload = {
      formId: el.form?.id,
    };

    unpublishFormApi(payload)
      .then(() => {
        getForms();
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const formsListArray =
    formsList &&
    formsList.map((el: any, index: number) => (
      <FormItemPreview
        key={index}
        el={el}
        handleFormOpen={handleFormOpen}
        handleDeleteForm={handleDeleteForm}
        handleUnpublishForm={handleUnpublishForm}
        getForms={getForms}
      />
    ));

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | My forms</title>
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        {!isCurrentUserPlanLoading && (
          <div className={styles.formsWrapper}>
            <Link to={isUserPlanActive ? ROUTES.FORM_BUILDER : ROUTES.PRICING}>
              <button className={styles.newFormButton}>
                <p>New form</p>
              </button>
            </Link>
            {!isLoading ? (
              formsListArray
            ) : (
              <RotatingLines
                visible={true}
                width="30"
                strokeWidth="5"
                animationDuration="0.75"
                strokeColor="black"
              />
            )}
          </div>
        )}
      </div>
      {formsList[0] && (
        <FormSubmissionEmailIntegration formsArray={formsList} />
      )}
    </div>
  );
};

export default Forms;
