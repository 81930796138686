import React, { useEffect, useState } from "react";
import styles from "./endingPage.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import { setCompanyLogoAC, setCompanyNameAC } from "src/store/actions";
import ROUTES from "src/_utils/routes/routes";
import { postFormApi, uploadFileApi } from "src/_utils/api/api";
import { IPostFormPayload } from "src/_utils/interfaces/interfaces";

const EndingPage = () => {
  const questionsList = useSelector((state: any) => state.questionsList);
  const companyName = useSelector((state: any) => state.companyName);
  const companyLogo = useSelector((state: any) => state.companyLogo);
  const endingPageText = useSelector((state: any) => state.endingPageText);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setTimeout(() => {
      isFormSaved && navigate(ROUTES.FORMS);
    }, 1000);
  }, [isFormSaved]);

  questionsList.map(
    (el: any) =>
      el.answerOptions &&
      el.answerOptions.map((option: any) => {
        if (option.icon && option.icon.startsWith("blob")) {
          const formData = new FormData();
          formData.append("file", option.iconFile);

          uploadFileApi(formData)
            .then((res) => {
              option.icon = res.data.data.fileUrl;
            })
            .catch((err) => console.log(err));
        }
        option.questionsArray &&
          option.questionsArray.map((el: any) => {
            el.answerOptions &&
              el.answerOptions.map((option: any) => {
                if (option.icon && option.icon.startsWith("blob")) {
                  const formData = new FormData();
                  formData.append("file", option.iconFile);

                  uploadFileApi(formData)
                    .then((res) => {
                      option.icon = res.data.data.fileUrl;
                    })
                    .catch((err) => console.log(err));
                }
              });
          });
      })
  );

  const saveForm = (e: any) => {
    setErrorMessage("");
    setIsLoading(true);

    e.preventDefault();
    const formData = new FormData();
    formData.append("file", companyLogo);

    uploadFileApi(formData)
      .then((res) => {
        const payload: IPostFormPayload = {
          name: companyName,
          formsData: {
            logo: res.data.data.fileUrl,
            questions: questionsList,
            endingPageText: endingPageText,
          },
        };

        postFormApi(payload).then(() => {
          setIsLoading(false);
          dispatch(setCompanyNameAC(""));
          dispatch(setCompanyLogoAC(null));
          setIsFormSaved(true);
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h4>Your form is ready</h4>
        {!isFormSaved && (
          <button onClick={saveForm} className={styles.button}>
            {isLoading ? (
              <RotatingLines
                visible={true}
                width="30"
                strokeWidth="5"
                animationDuration="0.75"
                strokeColor="white"
              />
            ) : (
              "Save"
            )}
          </button>
        )}
        {isFormSaved && <p className={styles.formSavedLabel}>Form saved</p>}
        {errorMessage !== "" && (
          <p className={styles.formSaveErrorLabel}>{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default EndingPage;
