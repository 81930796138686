import styles from "./downloader.module.scss";
import csvIcon from "./../../../assets/leadsPage/csv.svg";
import pdfIcon from "./../../../assets/leadsPage/pdf.svg";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Downloader: React.FC<any> = ({ leadsListForDownload }: any) => {
  function downloadCSV(data: any) {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "data.csv");
  }

  function downloadPDF(data: any[]) {
    if (data.length === 0) {
      console.log('No data to generate PDF');
      return;
    }
  
    const doc = new jsPDF();
    doc.text("Form Data", 20, 10);
  
    const headers = Object.keys(data[0]).map(key => key);
  
    const body = data.map(item => headers.map(header => item[header]));
  
    doc.autoTable({
      head: [headers],
      body: body,
    });
  
    doc.save('data.pdf');
  }

  return (
    <div className={styles.downloadsWrapper}>
      <button
        onClick={() => downloadCSV(leadsListForDownload)}
        className={styles.downlaodButton}
      >
        <img src={csvIcon} alt="download csv" />
      </button>
      <button
        onClick={() => downloadPDF(leadsListForDownload)}
        className={styles.downlaodButton}
      >
        <img src={pdfIcon} alt="download pdf" />
      </button>
    </div>
  );
};

export default Downloader;
