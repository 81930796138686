import React, { useEffect } from "react";
import AddElementButton from "src/_elements/addElementButton/addElementButton";
import FormInput from "src/_elements/formInput/formInput";
import styles from "./chooseQuestions.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setContinueButtonDisabledAC, setQuestionsAC } from "src/store/actions";
import { IQuestion } from "../../interfaces/interfaces";
import trashIcon from "src/assets/trash.svg";

const ChooseQuestions = () => {
  const dispatch = useDispatch();
  const questionsList = useSelector((state: any) => state.questionsList);

  useEffect(() => {
    dispatch(setContinueButtonDisabledAC(false));

    questionsList.forEach((el: any) => {
      if (el.question === "") {
        dispatch(setContinueButtonDisabledAC(true));
      }
    });
  }, [questionsList]);

  const addQuestion = () => {
    dispatch(
      setQuestionsAC([...questionsList, { question: "", answerOptions: null }])
    );
  };

  const handleQuestionChange = (index: number, value: string) => {
    const newQuestions = [...questionsList];
    newQuestions[index].question = value;
    dispatch(setQuestionsAC(newQuestions));
  };

  const removeQuestion = (index: number) => {
    const newQuestions = questionsList.filter((_: any, i: any) => i !== index);
    dispatch(setQuestionsAC(newQuestions));
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h2>Enter questions</h2>
        <div className={styles.content}>
          {questionsList.map((el: IQuestion, index: number) => (
            <div key={index} className={styles.questionWrapper}>
              <div className={styles.questionInnerWrap}>
                <FormInput
                  placeholder={`Question ${index + 1}`}
                  value={el.question}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleQuestionChange(index, e.target.value)
                  }
                />
                <button
                  onClick={() => removeQuestion(index)}
                  className={styles.removeQuestionBtn}
                >
                  <img src={trashIcon} alt="delete" />
                </button>
              </div>
            </div>
          ))}
          <div className={styles.addQuestionBtn}>
            <AddElementButton onClick={addQuestion}>
              + Add question
            </AddElementButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseQuestions;
