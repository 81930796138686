import styles from "./top.module.scss";
import placeholder from "src/assets/whoWeServe/generalContracting/placeholder.png";

const Top = () => {
  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <div>
          <h1>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam in
            ipsam molestias aut mollitia nesciunt necessitatibus nulla. Odit
            eius cupiditate unde velit voluptates, debitis laborum! Tenetur quas
            fuga unde sed.
          </p>
        </div>
        <img src={placeholder} alt="placeholder" />
      </div>
      <div className={styles.slantWrapper}>
        <div className={styles.slantBot}></div>
      </div>
    </div>
  );
};

export default Top;
