import Header from "src/pages/homePage/header/header";
import styles from "./generalContracting.module.scss";
import Top from "./top/top";
import Test from "./test/test";
import Integrates from "src/pages/homePage/integrates/integrates";
import Footer from "src/pages/homePage/footer/footer";
import { Helmet } from "react-helmet";

const GeneralContracting = () => {
  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | General contracting</title>
      </Helmet>
      <Header />
      <Test />
      <Top />
      <Test />
      <Top />
      <Integrates />
      <div className={styles.footerWrapper}>
        <Footer />
      </div>
    </div>
  );
};

export default GeneralContracting;
