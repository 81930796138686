import { Link } from "react-router-dom";
import styles from "./getUp.module.scss";
import ROUTES from "src/_utils/routes/routes";

const GetUp = () => {
  const token = localStorage.getItem("token");

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h6>Get up and running in less than 2 minutes.</h6>
        <Link
          className={styles.button}
          to={token ? ROUTES.PRICING : ROUTES.LOGIN}
        >
          <button>Get started</button>
        </Link>
      </div>
    </div>
  );
};

export default GetUp;
