import { useEffect } from "react";
import Footer from "../homePage/footer/footer";
import Header from "../homePage/header/header";
import CardItem from "./cardItem/cardItem";
import styles from "./whoWeServe.module.scss";
import { Link } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import { Helmet } from "react-helmet";
import generalContracting from "src/assets/whoWeServe/generalContracting.png";
import HVAC from "src/assets/whoWeServe/HVAC.png";
import roofing from "src/assets/whoWeServe/roofing.png";
import solar from "src/assets/whoWeServe/solar.png";
import plumbing from "src/assets/whoWeServe/plumbing.png";
import electrical from "src/assets/whoWeServe/electrical.png";
import painting from "src/assets/whoWeServe/painting.png";
import pestControl from "src/assets/whoWeServe/pestControl.png";
import landscapingAndLawnCare from "src/assets/whoWeServe/landscaping&LawnCare.png";
import poolConstruction from "src/assets/whoWeServe/poolConstruction.png";
import cleaningServices from "src/assets/whoWeServe/cleaningServices.png";
import epoxyCoating from "src/assets/whoWeServe/epoxyCoating.png";
import flooring from "src/assets/whoWeServe/flooring.png";
import homeRenovation from "src/assets/whoWeServe/homeRenovation.png";
import garageDoorServices from "src/assets/whoWeServe/garageDoorServices.png";
import holidayLightInstallation from "src/assets/whoWeServe/holidayLightInstallation.png";
import windowCleaning from "src/assets/whoWeServe/windowCleaning.png";
import homeSecurity from "src/assets/whoWeServe/homeSecurity.png";
import applianceRepair from "src/assets/whoWeServe/applianceRepair.png";
import interiorDesign from "src/assets/whoWeServe/interiorDesign.png";
import furnitureAssembly from "src/assets/whoWeServe/furnitureAssembly.png";
import homeOrganization from "src/assets/whoWeServe/homeOrganization.png";
import masonry from "src/assets/whoWeServe/masonry.png";
import deckAndPatioConstruction from "src/assets/whoWeServe/deckAndPatioConstruction.png";
import fencing from "src/assets/whoWeServe/fencing.png";
import waterproofing from "src/assets/whoWeServe/waterproofing.png";

const WhoWeServe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={styles.outerWrapper}>
        <Helmet>
          <title>LeadQuiz | Who we serve</title>
        </Helmet>
        <Header />
        <div className={styles.wrapper}>
          <Link to={`${ROUTES.WHO_WE_SERVE}${ROUTES.GENERAL_CONTRACTING}`}>
            <CardItem img={generalContracting} text="General Contracting" />
          </Link>
          <CardItem
            img={HVAC}
            text="HVAC (Heating, Ventilation, and Air Conditioning)"
          />
          <CardItem img={roofing} text="Roofing" />
          <CardItem img={solar} text="Solar" />
          <CardItem img={plumbing} text="Plumbing" />
          <CardItem img={electrical} text="Electrical" />
          <CardItem img={painting} text="Painting" />
          <CardItem img={pestControl} text="Pest Control" />
          <CardItem
            img={landscapingAndLawnCare}
            text="Landscaping & Lawn Care"
          />
          <CardItem img={poolConstruction} text="Pool Construction" />
          <CardItem img={cleaningServices} text="Cleaning Services" />
          <CardItem img={epoxyCoating} text="Epoxy Coating" />
          <CardItem img={flooring} text="Flooring" />
          <CardItem img={homeRenovation} text="Home Renovation" />
          <CardItem img={garageDoorServices} text="Garage Door Services" />
          <CardItem
            img={holidayLightInstallation}
            text="Holiday Light Installation"
          />
          <CardItem img={windowCleaning} text="Window Cleaning" />
          <CardItem img={homeSecurity} text="Home Security" />
          <CardItem img={applianceRepair} text="Appliance Repair" />
          <CardItem img={interiorDesign} text="Interior Design" />
          <CardItem img={furnitureAssembly} text="Furniture Assembly" />
          <CardItem img={homeOrganization} text="Home Organization" />
          <CardItem img={masonry} text="Masonry" />
          <CardItem
            img={deckAndPatioConstruction}
            text="Deck and Patio Construction"
          />
          <CardItem img={fencing} text="Fencing" />
          <CardItem img={waterproofing} text="Waterproofing" />
        </div>
      </div>
      <div className={styles.footerOuterWrapper}>
        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default WhoWeServe;
