import {
  getAvailablePlansListApi,
  getCurrentUserInfoApi,
  getCurrentUserPlanApi,
  getPlanAdditionalPurchaseAvailableApi,
  getPlanAdditionalPurchaseCurrentApi,
  getSubscriptionScheduleApi,
} from "src/_utils/api/api";
import Footer from "../homePage/footer/footer";
import Header from "../homePage/header/header";
import styles from "./settingsPage.module.scss";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { IPlanData } from "../interfaces/interfaces";
import { Link, useNavigate } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import { useSelector } from "react-redux";
import CurrentPlan from "./currentPlan/currentPlan";
import NextPlan from "./nextPlan/nextPlan";
import ActiveAdditionalPurchases from "./activeAdditionalPurchases/activeAdditionalPurchases";
import ApiAccess from "./apiAccess/apiAccess";

const SettingsPage = () => {
  const token = localStorage.getItem("token");

  const observeNextPlanChanged = useSelector(
    (state: any) => state.observeNextPlanChanged
  );
  const isUserPlanActive = useSelector((state: any) => state.isUserPlanActive);

  const navigate = useNavigate();

  const [currentPlanName, setCurrentPlanName] = useState();
  const [maxDomains, setMaxDomains] = useState();
  const [maxSubmissionsPerMonth, setMaxSubmissionsPerMonth] = useState();
  const [planPrice, setPlanPrice] = useState(0);
  const [currentPlanId, setCurrentPlanId] = useState();
  const [availablePlansList, setAvailablePlansList] = useState<any>([]);

  const [currentPlanStartDateNoScheduule, setCurrentPlanStartDateNoScheduule] =
    useState("");
  const [currentPlanEndDateNoScheduule, setCurrentPlanEndDateNoScheduule] =
    useState("");

  const [currentPlanStartDate, setCurrentPlanStartDate] = useState("");
  const [currentPlanEndDate, setCurrentPlanEndDate] = useState("");

  const [nextPlanStartDate, setNextPlanStartDate] = useState("");
  const [nextPlanEndDate, setNextPlanEndDate] = useState("");
  const [nextPlanData, setNextPlanData] = useState<any>();

  const [currentSubscriptionSchedule, setCurrentSubscriptionSchedule] =
    useState<any>();
  const [nextPlanDataPaymentType, setNextPlanDataPaymentType] = useState<
    "month" | "year" | null
  >(null);
  const [nextPlanPriceId, setNextPlanPriceId] = useState("");
  const [currentPlanDataPriceId, setCurrentPlanDataPriceId] = useState<any>([]);
  const [isCurrentPlanChanged, setIsCurrentPlanChanged] = useState(
    currentSubscriptionSchedule?.phases[1]
  );
  const [additionalPurchasesList, setAdditionalPurchasesList] = useState([]);
  const [totalAdditionalSubmissionsCount, setTotalAdditionalSubmissionsCount] =
    useState(0);
  const [additionalPurchases, setAdditionalPurchases] = useState<any>([]);
  const [currentUserZapierApiKey, setCurrentUserZapierApiKey] = useState("");
  const [zapierApiKeyCopyStatus, setZapierApiKeyCopyStatus] = useState("");
  const [userCurrentEmail, setUserCurrentEmail] = useState("");
  const [isNeedToContactSales, setSsNeedToContactSales] = useState(false);
  const [isNeedUpdatePlan, setisNeedUpdatePlan] = useState(false);
  const [isCurrentPlanAvailable, setIsCurrentPlanAvailable] = useState(false);
  const [nextPlanPrice, setNextPlanPrice] = useState();
  const [periodLabel, setPeriodLabel] = useState("");
  const [periodLabelNextPlan, setPeriodLabelNextPlan] = useState("");
  const [isPlanPermanent, setIsPlanPermanent] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (currentPlanName && isUserPlanActive) {
      setIsCurrentPlanAvailable(true);
    } else {
      setIsCurrentPlanAvailable(false);
    }
  }, [currentPlanName, isUserPlanActive]);

  useEffect(() => {
    if (zapierApiKeyCopyStatus !== "") {
      setTimeout(() => {
        setZapierApiKeyCopyStatus("");
      }, 2000);
    }
  }, [zapierApiKeyCopyStatus]);

  useEffect(() => {
    if (!token) {
      navigate(ROUTES.HOME);
    }

    if (token) {
      getPlanAdditionalPurchaseCurrentApi().then((res) => {
        setAdditionalPurchasesList(res.data.data);
        setTotalAdditionalSubmissionsCount(
          res.data.data?.reduce(
            (acc: number, el: any) => acc + el.additionalPurchase.count,
            0
          )
        );
      });
    }

    getCurrentUserInfoApi().then((res) => {
      setCurrentUserZapierApiKey(res.data.data.apiKey);
      setUserCurrentEmail(res.data.data.login);
    });
  }, [token]);

  useEffect(() => {
    if (token) {
      getCurrentUserPlanApi()
        .then((res: any) => {
          setIsPlanPermanent(res.data.data.isPermanent);
          getAvailablePlansListApi()
            .then((result) => {
              const foundPlan = result.data.data.find(
                (plan: IPlanData) => plan.id === res.data.data?.planId
              );
              setSsNeedToContactSales(foundPlan.needToContactSales);
              setCurrentPlanName(foundPlan?.name);
              setMaxDomains(foundPlan?.maxDomains);
              setMaxSubmissionsPerMonth(foundPlan?.maxSubmissionsPerMonth);
              if (
                foundPlan.stripePrices.pricePerMonth.id ===
                currentPlanDataPriceId
              ) {
                setPeriodLabel("month");
                setPlanPrice(foundPlan?.stripePrices.pricePerMonth.unit_amount);
              } else if (
                foundPlan.stripePrices.pricePerYear.id ===
                currentPlanDataPriceId
              ) {
                setPeriodLabel("year");
                setPlanPrice(foundPlan?.stripePrices.pricePerYear.unit_amount);
              }
              setCurrentPlanId(foundPlan?.id);
              setAvailablePlansList(result.data.data);
            })
            .catch((err) => console.log(err));
          setCurrentPlanDataPriceId(res.data.data.priceId);
          setCurrentPlanStartDateNoScheduule(res.data.data.startAt);
          setCurrentPlanEndDateNoScheduule(res.data.data.endAt);
          setisNeedUpdatePlan(res.data.data.needUpdatePlan);
        })
        .catch((err) => console.log(err));
    }

    currentPlanName &&
      token &&
      !isPlanPermanent &&
      getSubscriptionScheduleApi()
        .then((res: any) => {
          const timestampStartDate =
            res.data.data[0]?.current_phase.start_date * 1000;
          const dateStartDate = new Date(timestampStartDate);
          const formattedDateOnlyStartDate =
            dateStartDate.toLocaleDateString("en-US");
          setCurrentPlanStartDate(formattedDateOnlyStartDate);

          const timestampEndDate =
            res.data.data[0]?.current_phase.end_date * 1000;
          const dateEndDate = new Date(timestampEndDate);
          const formattedDateOnlyEndDate =
            dateEndDate.toLocaleDateString("en-US");
          setCurrentPlanEndDate(formattedDateOnlyEndDate);

          const timestampNextPlanStartDate =
            res.data.data[0]?.phases[1]?.start_date * 1000;
          const dateNextPlanStartDate = new Date(timestampNextPlanStartDate);
          const formattedDateOnlyNextPlanStartDate =
            dateNextPlanStartDate.toLocaleDateString("en-US");
          setNextPlanStartDate(formattedDateOnlyNextPlanStartDate);

          const timestampNextPlanEndDate =
            res.data.data[0]?.phases[1]?.end_date * 1000;
          const dateNextPlanEndDate = new Date(timestampNextPlanEndDate);
          const formattedDateOnlyNextPlanEndDate =
            dateNextPlanEndDate.toLocaleDateString("en-US");
          setNextPlanEndDate(formattedDateOnlyNextPlanEndDate);

          setNextPlanPriceId(res.data.data[0]?.phases[1]?.items[0].price);

          setCurrentSubscriptionSchedule(res.data.data[0]);
        })
        .catch((err) => console.log(err));
  }, [currentPlanId, observeNextPlanChanged, token]);

  useEffect(() => {
    const nextPlanDataArray = availablePlansList.find(
      (el: any) =>
        el.stripePrices.pricePerMonth.id === nextPlanPriceId ||
        el.stripePrices.pricePerYear.id === nextPlanPriceId
    );

    setNextPlanData(nextPlanDataArray);

    if (currentSubscriptionSchedule && currentSubscriptionSchedule.phases) {
      availablePlansList.map((el: any) => {
        el.stripePrices.pricePerMonth.id ===
          currentSubscriptionSchedule?.phases[1]?.items[0].price &&
          setNextPlanDataPaymentType("month");

        el.stripePrices.pricePerYear.id ===
          currentSubscriptionSchedule?.phases[1]?.items[0].price &&
          setNextPlanDataPaymentType("year");
      });
    }
  }, [currentSubscriptionSchedule]);

  useEffect(() => {
    currentPlanName &&
      token &&
      getPlanAdditionalPurchaseAvailableApi()
        .then((res) => {
          setAdditionalPurchases(res.data.data);
        })
        .catch((err) => console.log(err));
  }, [currentPlanName, token]);

  useEffect(() => {
    if (nextPlanDataPaymentType === "month") {
      setNextPlanPrice(nextPlanData?.stripePrices.pricePerMonth.unit_amount);
      setPeriodLabelNextPlan("month");
    } else if (nextPlanDataPaymentType === "year") {
      setNextPlanPrice(nextPlanData?.stripePrices.pricePerYear.unit_amount);
      setPeriodLabelNextPlan("year");
    }
  }, [nextPlanDataPaymentType, nextPlanData]);

  const additionalPurchasesListArray =
    additionalPurchasesList && additionalPurchasesList[0]
      ? additionalPurchasesList.map((el: any, index) => (
          <p key={index}>{el.additionalPurchase.name}</p>
        ))
      : null;

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(currentUserZapierApiKey)
      .then(() => {
        setZapierApiKeyCopyStatus("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Settings</title>
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.elementWrapper}>
            <CurrentPlan
              isCurrentPlanAvailable={isCurrentPlanAvailable}
              currentPlanName={currentPlanName}
              planPrice={planPrice}
              maxDomains={maxDomains}
              maxSubmissionsPerMonth={maxSubmissionsPerMonth}
              currentSubscriptionSchedule={currentSubscriptionSchedule}
              isCurrentPlanChanged={isCurrentPlanChanged}
              setIsCurrentPlanChanged={setIsCurrentPlanChanged}
              totalAdditionalSubmissionsCount={totalAdditionalSubmissionsCount}
              additionalPurchases={additionalPurchases}
              isNeedToContactSales={isNeedToContactSales}
              isNeedUpdatePlan={isNeedUpdatePlan}
              periodLabel={periodLabel}
              currentPlanStartDate={currentPlanStartDate}
              currentPlanStartDateNoScheduule={currentPlanStartDateNoScheduule}
              currentPlanEndDate={currentPlanEndDate}
              currentPlanEndDateNoScheduule={currentPlanEndDateNoScheduule}
              isPlanPermanent={isPlanPermanent}
            />
            <NextPlan
              nextPlanData={nextPlanData}
              isCurrentPlanChanged={isCurrentPlanChanged}
              nextPlanPrice={nextPlanPrice}
              periodLabel={periodLabelNextPlan}
              nextPlanStartDate={nextPlanStartDate}
              nextPlanEndDate={nextPlanEndDate}
            />
          </div>
          {additionalPurchasesListArray && (
            <div className={styles.elementWrapper}>
              <ActiveAdditionalPurchases
                additionalPurchasesListArray={additionalPurchasesListArray}
              />
            </div>
          )}
          <div className={styles.formSubmissionWrapper}>
            <div className={styles.elementWrapper}>
              <ApiAccess
                currentUserZapierApiKey={currentUserZapierApiKey}
                handleCopyClick={handleCopyClick}
                zapierApiKeyCopyStatus={zapierApiKeyCopyStatus}
              />
            </div>
          </div>
          <div className={styles.changeUserDataOuterWrapper}>
            <div className={styles.elementWrapper}>
              <div className={styles.changeUserDataWrapper}>
                <p className={styles.title}>Change email:</p>
                <p className={styles.currentEmailLabel}>{userCurrentEmail}</p>
                <Link to={ROUTES.CHANGE_EMAIL} className={styles.btn}>
                  Change email
                </Link>
              </div>
            </div>
            <div className={styles.elementWrapper}>
              <div className={styles.changeUserDataWrapper}>
                <p className={styles.title}>Change password:</p>
                <Link to={ROUTES.FORGOT_PASSWORD} className={styles.btn}>
                  Change password
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SettingsPage;
function setCurrentUserZapierApiKey(apiKey: any) {
  throw new Error("Function not implemented.");
}

