import styles from "./cardItem.module.scss";

const CardItem: React.FC<any> = ({ img, text }: any) => {
  return (
    <div className={styles.cardItem}>
      <img src={img} />
      <p>{text}</p>
    </div>
  );
};

export default CardItem;
