import React, { useEffect, useState } from "react";
import styles from "./formView.module.scss";
import { useDispatch, useSelector } from "react-redux";
import FormViewFooter from "./formViewFooter/formViewFooter";
import QuestionPage from "./questionPage/questionPage";
import { IQuestion } from "../formBuilder/interfaces/interfaces";
import { RotatingLines } from "react-loader-spinner";
import {
  clearUserAnswersAC,
  setCurrentFormPageAC,
  setQuestionsAC,
} from "src/store/actions";
import { getFormApi, getPublishedFormApi } from "src/_utils/api/api";
import { Helmet } from "react-helmet";
import HowShallWeContactYou from "./howShallWeContactYou/howShallWeContactYou";
import { useParams } from "react-router-dom";
import PublishFormModal from "./publishFormModal/publishFormModal";

const FormView = () => {
  const questionsList = useSelector((state: any) => state.questionsList);
  const currentIndex = useSelector((state: any) => state.currentFormPage);

  const dispatch = useDispatch();

  const { id } = useParams();
  const subdomain = window.location.hostname.split(".")[0];

  const [currentQuestionsList, setCurrentQuestionsList] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [endingPageText, setEndingPageText] = useState("Thank you!");
  const [isShowEndingPage, setIsShowEndingPage] = useState(false);
  const [answerIndex, setAnswerIndex] = useState(0);
  const [currentFormId, setCurrentFormId] = useState("");
  const [isFormPublished, setIsFormPublished] = useState(false);
  const [isShowPublishModal, setIsShowPublishModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (id) {
      getFormApi(id)
        .then((res) => {
          dispatch(setQuestionsAC(res.data.data.formData.formsData));
          setEndingPageText(
            res.data.data.formData.formsData.endingPageText ?? "Thank you!"
          );
          setCurrentFormId(res.data.data.form.id);
          setIsFormPublished(res.data.data.form.isPublished);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else if (subdomain) {
      getPublishedFormApi(subdomain).then((res) => {
        setCurrentFormId(res.data.data.id);
        dispatch(setQuestionsAC(res.data.data.formsData));
        setEndingPageText(
          res.data.data.formsData.endingPageText ?? "Thank you!"
        );
        setIsLoading(false);
      });
    }

    return () => {
      dispatch(setCurrentFormPageAC(0));
      dispatch(clearUserAnswersAC());
    };
  }, []);

  const isLastPage = !currentQuestionsList
    ? currentIndex === questionsList.questions?.length
    : currentIndex === currentQuestionsList?.length;

  return (
    <>
      <div className={styles.outerWrapper}>
        <Helmet>
          <title>LeadQuiz | Form</title>
        </Helmet>
        {!isFormPublished && !isLoading && id && (
          <div className={styles.publshMessage}>
            <p>
              You are currently in viewing mode. Publish the form so others can
              respond to it.
            </p>
            <button onClick={() => setIsShowPublishModal(true)}>Publish</button>
          </div>
        )}
        <img
          className={styles.companyLogo}
          src={questionsList.logo}
          alt="company logo"
        />
        {!isLoading ? (
          <div className={styles.wrapper}>
            {!currentQuestionsList &&
              questionsList.questions.map((el: IQuestion, index: number) => (
                <QuestionPage
                  key={index}
                  element={el}
                  isVisible={index === currentIndex}
                  setCurrentQuestionsList={setCurrentQuestionsList}
                  answerIndex={answerIndex}
                  setAnswerIndex={setAnswerIndex}
                />
              ))}
            {currentQuestionsList &&
              //@ts-ignore
              currentQuestionsList.map((el: IQuestion, index: number) => (
                <QuestionPage
                  key={index}
                  element={el}
                  isVisible={index === currentIndex}
                  setCurrentQuestionsList={setCurrentQuestionsList}
                  answerIndex={answerIndex}
                  setAnswerIndex={setAnswerIndex}
                />
              ))}
            {isLastPage && !isShowEndingPage && <HowShallWeContactYou />}
            {isShowEndingPage && <h1>{endingPageText}</h1>}
            {!isShowEndingPage && (
              <FormViewFooter
                content={questionsList.questions}
                currentQuestionsList={currentQuestionsList}
                isLastPage={isLastPage}
                setIsShowEndingPage={setIsShowEndingPage}
                setAnswerIndex={setAnswerIndex}
                currentFormId={currentFormId}
              />
            )}
          </div>
        ) : (
          <RotatingLines
            visible={true}
            width="30"
            strokeWidth="5"
            animationDuration="0.75"
            strokeColor="black"
          />
        )}
      </div>
      <PublishFormModal
        isShowModal={isShowPublishModal}
        setIsShowModal={setIsShowPublishModal}
        formId={id}
      />
    </>
  );
};

export default FormView;
