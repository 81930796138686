import { Link } from "react-router-dom";
import styles from "./top.module.scss";
import ROUTES from "src/_utils/routes/routes";
import devices from "src/assets/homePage/devices.png";

const Top = () => {
  const token = localStorage.getItem("token");

  return (
    <div className={styles.wrapper}>
      <div>
        <h1>Start generating quality leads.</h1>
        <p>
          Replace your Facebook forms, and confusing websites with an
          interactive form. Allowing your business to increase lead quality and
          convert more clients.
        </p>
        <Link to={token ? ROUTES.PRICING : ROUTES.LOGIN}>
          <button>Get started today</button>
        </Link>
      </div>
      <img src={devices} alt="devices" />
    </div>
  );
};

export default Top;
