import React, { useState } from "react";
import styles from "./answerOptionsModal.module.scss";
import Modal from "src/_elements/modal/modal";
import AddAnswerOptionButton from "./addAnswerOptionButton/addAnswerOptionButton";
import AnswerOptionPreview from "./answerOptionPreview/answerOptionPreview";
import { useDispatch, useSelector } from "react-redux";
import {
  addAnswerOptionAC,
  addAnswerOptionToQuestionAC,
  setIsAnyAnswerOptionsAC,
  setQuestionsAC,
} from "src/store/actions";
import { truncateText } from "src/_utils/truncateText";

const AnswerOptionsModal: React.FC<any> = ({
  index,
  question,
  showAnswerOptionsModal,
  setShowAnswerOptionsModal,
  answerOptionIndex,
  isConditional,
  questionIndex,
}: any) => {
  const dispatch = useDispatch();
  const questionsList = useSelector((state: any) => state.questionsList);

  const [newAnswerOptions, setNewAnswerOptions] = useState<string[]>([]);
  const [newOptionText, setNewOptionText] = useState("");

  const handleAddAnswerOption = (index: number) => {
    setNewAnswerOptions([...newAnswerOptions, newOptionText]);
    setNewOptionText("");

    if (!isConditional) {
      dispatch(addAnswerOptionAC(index, newOptionText));
    } else {
      dispatch(
        addAnswerOptionToQuestionAC(questionIndex, answerOptionIndex, index)
      );
    }

    dispatch(setIsAnyAnswerOptionsAC(true));
  };

  const handleRemoveAnswerOption = (
    questionIndex: number,
    optionIndex: number,
    answerOptionIndex: number
  ) => {
    const newQuestionsList = [...questionsList];
    if (!isConditional) {
      newQuestionsList[questionIndex].answerOptions.splice(optionIndex, 1);
    } else {
      newQuestionsList[questionIndex]?.answerOptions[
        answerOptionIndex
      ]?.questionsArray[index]?.answerOptions.splice(optionIndex, 1);
    }
    dispatch(setQuestionsAC(newQuestionsList));
  };

  const handleCloseModal = () => {
    setShowAnswerOptionsModal(false);
    setNewAnswerOptions([]);
    setNewOptionText("");
  };

  return (
    <Modal isShow={showAnswerOptionsModal} onClose={handleCloseModal}>
      <p className={styles.title}>
        Set answer options for question: <br />
        <span>"{truncateText(question, 100)}"</span>
      </p>
      <div className={styles.answerOptionsWrapper}>
        {!isConditional &&
          questionsList[index].answerOptions &&
          questionsList[index].answerOptions.map(
            (option: any, optionIndex: number) => (
              <AnswerOptionPreview
                questionIndex={index}
                optionIndex={optionIndex}
                questionText={option.title}
                questionIcon={option.icon}
                handleRemoveAnswerOption={handleRemoveAnswerOption}
                index={index}
                key={index}
              />
            )
          )}
        {isConditional &&
          questionsList[questionIndex] &&
          questionsList[questionIndex]?.answerOptions[answerOptionIndex] &&
          questionsList[questionIndex]?.answerOptions[answerOptionIndex]
            .questionsArray[index] &&
          questionsList[questionIndex]?.answerOptions[answerOptionIndex]
            .questionsArray[index].answerOptions &&
          questionsList[questionIndex]?.answerOptions[
            answerOptionIndex
          ]?.questionsArray[index]?.answerOptions.map(
            (option: any, optionIndex: number) => (
              <AnswerOptionPreview
                questionIndex={index}
                currentQuestionIndex={questionIndex}
                answerOptionIndex={answerOptionIndex}
                optionIndex={optionIndex}
                questionText={option.title}
                questionIcon={option.icon}
                handleRemoveAnswerOption={handleRemoveAnswerOption}
                index={index}
                key={index}
                isConditional
              />
            )
          )}
        <AddAnswerOptionButton onClick={() => handleAddAnswerOption(index)}>
          + Add answer option
        </AddAnswerOptionButton>
      </div>
      <div className={styles.btnWrap}>
        <button onClick={handleCloseModal}>Save</button>
      </div>
    </Modal>
  );
};

export default AnswerOptionsModal;
