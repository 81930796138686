import { Link } from "react-router-dom";
import Benefits from "../homePage/benefits/benefits";
import Footer from "../homePage/footer/footer";
import Header from "../homePage/header/header";
import Payments from "../homePage/payments/payments";
import styles from "./pricing.module.scss";
import { useEffect } from "react";
import ROUTES from "src/_utils/routes/routes";
import { Helmet } from "react-helmet";

function Pricing() {
  const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Pricing</title>
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.pricingTopOuterWrapper}>
          <div className={styles.pricingTopWrapper}>
            <h1>Start generating quality leads.</h1>
            <p>
              Replace your Facebook forms, and confusing websites with an
              interactive form. Allowing your business to increase lead quality
              and convert more clients.
            </p>
            <Link
              className={styles.getStartedButton}
              to={token ? ROUTES.PRICING : ROUTES.LOGIN}
            >
              <button>Get started today</button>
            </Link>
            <button className={styles.demoButton}>Schedule a demo</button>
          </div>
        </div>
        <div className={styles.paymentsWrap}>
          <Payments />
        </div>
        <Benefits />
        <div className={styles.footerWrapper}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Pricing;
