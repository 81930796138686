import React, { useState } from "react";
import styles from "./conditionsModal.module.scss";
import Modal from "src/_elements/modal/modal";
import FormInput from "src/_elements/formInput/formInput";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuestionToAnswerOptionAC,
  setQuestionsAC,
  UpdateConditionalQuestionAC,
} from "src/store/actions";
import { IQuestion } from "src/pages/formBuilder/interfaces/interfaces";
import AddElementButton from "src/_elements/addElementButton/addElementButton";
import AnswerOptionsModal from "../../chooseAnswerOptions/answerOptionsModal/answerOptionsModal";
import trashIcon from "src/assets/trash.svg";

const ConditionsModal: React.FC<any> = ({
  questionIndex,
  answerOptionIndex,
  showConditionsModal,
  setShowConditionsModal,
}: any) => {
  const dispatch = useDispatch();

  const questionsList = useSelector((state: any) => state.questionsList);

  const [showAnswerOptionsModal, setShowAnswerOptionsModal] = useState(false);
  const [currentElement, setCurrentElement] = useState<IQuestion | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleModalOpen = (el: IQuestion, index: number) => {
    setShowAnswerOptionsModal(true);
    setCurrentIndex(index);
    setCurrentElement(el);
  };

  const addQuestion = () => {
    const questionsArray = { question: "", answerOptions: null };
    dispatch(
      addQuestionToAnswerOptionAC(
        questionIndex,
        answerOptionIndex,
        questionsArray
      )
    );
  };

  const handleQuestionChange = (newQuestionIndex: number, value: string) => {
    dispatch(
      UpdateConditionalQuestionAC(
        questionIndex,
        answerOptionIndex,
        value,
        newQuestionIndex
      )
    );
  };

  const handleRemoveQuestion = (newQuestionIndex: number) => {
    const newQuestionsList = [...questionsList];
    newQuestionsList[questionIndex].answerOptions[
      answerOptionIndex
    ].questionsArray.splice(newQuestionIndex, 1);
    dispatch(setQuestionsAC(newQuestionsList));
  };

  return (
    <Modal
      className={styles.wrapper}
      isShow={showConditionsModal}
      onClose={() => setShowConditionsModal(false)}
    >
      <p className={styles.title}>Type conditional questions</p>
      <div className={styles.questionsWrapper}>
        {questionsList[questionIndex].answerOptions[
          answerOptionIndex
        ].questionsArray?.map((el: IQuestion, newQuestionIndex: number) => (
          <div key={newQuestionIndex} className={styles.questionItem}>
            <div className={styles.questionItemWrapper}>
              <button onClick={() => handleModalOpen(el, newQuestionIndex)}>
                Add answer options
              </button>
              <FormInput
                placeholder={`Question ${newQuestionIndex + 1}`}
                value={el.question}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleQuestionChange(newQuestionIndex, e.target.value)
                }
              />
              <button
                className={styles.removeButton}
                onClick={() => handleRemoveQuestion(newQuestionIndex)}
              >
                <img src={trashIcon} alt="delete" />
              </button>
            </div>
          </div>
        ))}
        <div>
          <AddElementButton onClick={addQuestion}>
            + Add question
          </AddElementButton>
        </div>
      </div>
      <AnswerOptionsModal
        index={currentIndex}
        question={currentElement && currentElement.question}
        showAnswerOptionsModal={showAnswerOptionsModal}
        setShowAnswerOptionsModal={setShowAnswerOptionsModal}
        answerOptionIndex={answerOptionIndex}
        questionIndex={questionIndex}
        isConditional
      />
      <div className={styles.btnWrap}>
        <button onClick={() => setShowConditionsModal(false)}>Save</button>
      </div>
    </Modal>
  );
};

export default ConditionsModal;
