import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./answerOptionPreview.module.scss";
import {
  editIconAnswerOptionToQuestionAC,
  editTextAnswerOptionToQuestionAC,
  updateAnswerOptionIconAC,
  updateAnswerOptionTextAC,
} from "src/store/actions";
import IconSelect from "src/_elements/iconSelect/iconSelect";
import { ICustomIcon, IIcon } from "src/_elements/interfaces/interfaces";
import trashIcon from "src/assets/trash.svg";

const AnswerOptionPreview: React.FC<any> = ({
  questionIndex,
  optionIndex,
  questionText,
  onClick,
  questionIcon,
  disabled,
  handleRemoveAnswerOption,
  index,
  isConditional,
  currentQuestionIndex,
  answerOptionIndex,
}: any) => {
  const dispatch = useDispatch();

  const [isShowIconsList, setIsShowIconsList] = useState<boolean>(false);
  const [customIcon, setCustomIcon] = useState<ICustomIcon | null>(null);
  const [customIconPreview, setCustomIconPreview] = useState<string>("");

  useEffect(() => {
    if (customIconPreview) {
      if (!isConditional) {
        dispatch(
          updateAnswerOptionIconAC(
            questionIndex,
            optionIndex,
            customIconPreview,
            customIcon
          )
        );
      } else {
        dispatch(
          editIconAnswerOptionToQuestionAC(
            currentQuestionIndex,
            answerOptionIndex,
            index,
            optionIndex,
            customIconPreview,
            customIcon
          )
        );
      }
    }
  }, [customIconPreview]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isConditional) {
      dispatch(
        updateAnswerOptionTextAC(questionIndex, optionIndex, e.target.value)
      );
    } else {
      dispatch(
        editTextAnswerOptionToQuestionAC(
          currentQuestionIndex,
          answerOptionIndex,
          index,
          optionIndex,
          e.target.value
        )
      );
    }
  };

  const handleIconSelect = (icon: IIcon) => {
    setIsShowIconsList(false);

    if (!isConditional) {
      dispatch(
        updateAnswerOptionIconAC(
          questionIndex,
          optionIndex,
          icon.raster_sizes[
            icon.raster_sizes[4] ? 4 : icon.raster_sizes.length - 1
          ].formats[0].preview_url
        )
      );
    } else {
      dispatch(
        editIconAnswerOptionToQuestionAC(
          currentQuestionIndex,
          answerOptionIndex,
          index,
          optionIndex,
          icon.raster_sizes[
            icon.raster_sizes[4] ? 4 : icon.raster_sizes.length - 1
          ].formats[0].preview_url
        )
      );
    }
  };

  const handleDeleteButtonClicked = () => {
    if (!isConditional) {
      handleRemoveAnswerOption(index, optionIndex);
    } else {
      handleRemoveAnswerOption(
        currentQuestionIndex,
        optionIndex,
        answerOptionIndex
      );
    }
  };

  const toggleDropdown = () => {
    if (!disabled) {
      setIsShowIconsList(!isShowIconsList);
    }
  };

  const inputFinalClass = questionText === "" ? styles.inputEmpty : "";

  return (
    <div className={styles.wrapper} onClick={onClick}>
      {!disabled && (
        <button
          className={styles.removeButton}
          onClick={handleDeleteButtonClicked}
        >
          <img src={trashIcon} alt="delete" />
        </button>
      )}
      <IconSelect
        questionIcon={questionIcon}
        handleSelect={handleIconSelect}
        toggleDropdown={toggleDropdown}
        setIsShowIconsList={setIsShowIconsList}
        isShowIconsList={isShowIconsList}
        setCustomIcon={setCustomIcon}
        customIconPreview={customIconPreview}
        setCustomIconPreview={setCustomIconPreview}
      />
      <input
        className={`${styles.questionText} ${inputFinalClass}`}
        placeholder="Type..."
        type="text"
        value={questionText}
        onChange={handleChange}
        maxLength={30}
      />
    </div>
  );
};

export default AnswerOptionPreview;
