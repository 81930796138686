import { ILeadsPagination } from "../interfaces/interfaces";
import styles from "./pagination.module.scss";

const Pagination: React.FC<ILeadsPagination> = ({
  handlePageChange,
  currentPage,
  totalPages,
}: ILeadsPagination) => {
  return (
    <div className={styles.wrapper}>
      <button
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        Previous
      </button>
      <span>
        Page {currentPage} of {totalPages}
      </span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
