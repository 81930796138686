import Header from "../homePage/header/header";
import styles from "./adminPage.module.scss";
import { useEffect, useState } from "react";
import Plan from "./plan/plan";
import ConfigPlanModal from "./configPlanModal/configPlanModal";
import { getPlansListApi } from "src/_utils/api/api";
import { IPlanData } from "../interfaces/interfaces";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import { getUserRole } from "src/_utils/userRoles/getUserRole";
import ROLES from "src/_utils/userRoles/userRoles";
import AddSubmissionsModal from "./addSubmissionsModal/addSubmissionsModal";
import PersonalPlans from "./personalPlans/personalPlans";

const AdminPage = () => {
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const userRole = getUserRole(token);

  const [plansList, setPlansList] = useState<IPlanData[] | null>(null);
  const [isShowAddSubmissionsModal, setIsShowAddSubmissionsModal] =
    useState(false);
  const [isShowPlanEditModal, setIsShowPlanEditModal] = useState(false);
  const [selectedPlanData, setSelectedPlanData] = useState<null | IPlanData>(
    null
  );
  const [isPersonalPlan, setIsPersonalPlan] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    getPlansListApi()
      .then((res) => {
        setPlansList(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!token || userRole[0] !== ROLES.ADMIN) {
      navigate(ROUTES.HOME);
    }
  }, [token]);

  const handleAddNewPlan = () => {
    setSelectedPlanData(null);
    setIsShowPlanEditModal(true);
  };

  const publicPlans = plansList?.filter((plan) => plan.userId === undefined);
  const personalPlans = plansList?.filter((plan) => plan.userId !== undefined);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | Admin page</title>
      </Helmet>
      <Header />
      <div className={styles.sectionWrapper}>
        <p className={styles.title}>Public plans:</p>
        <div className={styles.wrapper}>
          <button className={styles.button} onClick={handleAddNewPlan}>
            <p>Add new plan</p>
          </button>
          {publicPlans &&
            publicPlans.map((item, index) => (
              <Plan
                key={index}
                planData={item}
                setIsShowAddSubmissionsModal={setIsShowAddSubmissionsModal}
                setIsShowPlanEditModal={setIsShowPlanEditModal}
                setSelectedPlanData={setSelectedPlanData}
              />
            ))}
        </div>
      </div>
      <div>
        <p className={styles.title}>Personal plans:</p>
        <PersonalPlans
          handleAddNewPlan={handleAddNewPlan}
          setIsPersonalPlan={setIsPersonalPlan}
          personalPlans={personalPlans}
          setIsShowAddSubmissionsModal={setIsShowAddSubmissionsModal}
          setIsShowPlanEditModal={setIsShowPlanEditModal}
          setSelectedPlanData={setSelectedPlanData}
        />
      </div>
      <AddSubmissionsModal
        isShowAddSubmissionsModal={isShowAddSubmissionsModal}
        handleCloseModal={() => setIsShowAddSubmissionsModal(false)}
        selectedPlanData={selectedPlanData}
      />
      <ConfigPlanModal
        isShowPlanEditModal={isShowPlanEditModal}
        handleCloseModal={() => setIsShowPlanEditModal(false)}
        selectedPlanData={selectedPlanData}
        setPlansList={setPlansList}
        isPersonalPlan={isPersonalPlan}
        setIsPersonalPlan={setIsPersonalPlan}
      />
    </div>
  );
};

export default AdminPage;
