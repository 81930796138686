import styles from "./formViewFooter.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { sendUserAnswersApi } from "src/_utils/api/api";
import {
  setCurrentFormPageAC,
  setHowShallWeContactYouErrorAC,
  setUserNameAC,
} from "src/store/actions";

const FormViewFooter: React.FC<any> = ({
  content,
  currentQuestionsList,
  isLastPage,
  setIsShowEndingPage,
  setAnswerIndex,
  currentFormId,
}: any) => {
  const dispatch = useDispatch();

  const currentIndex = useSelector((state: any) => state.currentFormPage);
  const userInfo = useSelector((state: any) => state.userInfo);
  const userAnswers = useSelector((state: any) => state.userAnswers);
  const userName = useSelector((state: any) => state.userName);

  const { firstName, lastName, phoneNumber, email } = userInfo;

  const handleNext = () => {
    setAnswerIndex((prevState: number) => ++prevState);
    if (
      !currentQuestionsList
        ? currentIndex < content.length
        : currentIndex < currentQuestionsList.length
    ) {
      dispatch(setCurrentFormPageAC(currentIndex + 1));
    }
  };

  const keyCount: { [key: string]: number } = {};

  const transformedAnswers = userAnswers.map((item: any) => {
    let key = item?.question;

    if (keyCount[key]) {
      keyCount[key]++;
      key += keyCount[key];
    } else {
      keyCount[key] = 1;
    }

    return { [key]: item?.answer };
  });

  const arrayOfObjects = Object.entries(userInfo).map(([key, value]) => ({
    [key]: value,
  }));

  const combinedArray = transformedAnswers.concat(arrayOfObjects);

  const resultObj = combinedArray.reduce((acc: any, item: any) => {
    const key = Object.keys(item)[0];
    acc[key] = item[key];
    return acc;
  }, {});

  const userAnswersResult = {
    formId: currentFormId,
    username: userName,
    formData: resultObj,
  };

  const handleSendAnswers = () => {
    if (
      firstName !== "" &&
      lastName !== "" &&
      phoneNumber !== "" &&
      email !== ""
    ) {
      sendUserAnswersApi(userAnswersResult).then(() => {
        dispatch(setUserNameAC(""));
        setIsShowEndingPage(true);
      });
    } else {
      dispatch(setHowShallWeContactYouErrorAC(true));
    }
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        {!isLastPage &&
          (currentQuestionsList
            ? !currentQuestionsList[currentIndex]?.answerOptions?.length
            : !content[currentIndex]?.answerOptions?.length) && (
            <button className={styles.continueButton} onClick={handleNext}>
              Continue
            </button>
          )}
        {isLastPage && (
          <button onClick={handleSendAnswers} className={styles.continueButton}>
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default FormViewFooter;
