import React, { useEffect, useState } from "react";
import styles from "./toggleSwitch.module.scss";
import { IToggleSwitch } from "../interfaces/interfaces";

const ToggleSwitch: React.FC<IToggleSwitch> = ({
  toggleChange,
  value
}: IToggleSwitch) => {

  const handleToggle = () => {
    toggleChange(!value);
  };

  return (
    <label className={styles["toggle-switch"]}>
      <input type="checkbox" checked={value} onChange={handleToggle} />
      <span className={styles.slider}></span>
    </label>
  );
};

export default ToggleSwitch;
