import { Link, useNavigate } from "react-router-dom";
import styles from "./loginComponent.module.scss";
import { RotatingLines } from "react-loader-spinner";
import ROUTES from "src/_utils/routes/routes";
import companyLogo from "src/assets/homePage/companyLogo.png";
import googleLogo from "src/assets/signupPage/googleLogo.webp";
import { useEffect, useState } from "react";
import { ILoginPayload } from "src/_utils/interfaces/interfaces";
import { loginApi } from "src/_utils/api/api";
import { useDispatch } from "react-redux";
import {
  setIsShowLoginModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";

const LogInComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (token) {
      navigate(ROUTES.HOME);
    }
  }, [token]);

  const login = () => {
    if (userEmail !== "" && userPassword !== "") {
      setIsLoading(true);

      const payload: ILoginPayload = {
        login: userEmail,
        password: userPassword,
      };

      loginApi(payload)
        .then((res: any) => {
          localStorage.setItem("token", res.data.data.accessToken);
          setIsError(false);
          setIsLoading(false);
          dispatch(setIsShowLoginModalAC(false));
          navigate(ROUTES.HOME);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsError(true);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Link to={ROUTES.HOME}>
          <img
            width="170px"
            height="68px"
            src={companyLogo}
            alt="company logo"
          />
        </Link>
        <div className={styles.inputsWrapper}>
          <input
            className={isError ? styles.errorInput : ""}
            value={userEmail}
            onChange={(e) => setUserEmail(e.currentTarget.value)}
            type="email"
            autoComplete="email"
            placeholder="Email Address"
          />
          <input
            className={isError ? styles.errorInput : ""}
            value={userPassword}
            onChange={(e) => setUserPassword(e.currentTarget.value)}
            type="password"
            placeholder="Password"
          />
        </div>
        {isError && (
          <p className={styles.errorMessage}>Email or password is incorrect</p>
        )}
        <button className={styles.btn} onClick={login}>
          {!isLoading ? (
            "Login"
          ) : (
            <RotatingLines
              visible={true}
              width="30"
              strokeWidth="5"
              animationDuration="0.75"
              strokeColor="white"
            />
          )}
        </button>
        <p className={styles.labelOr}>OR</p>
        <div className={styles.alternativeSignUpVariants}>
          <a
            className={styles.googleLogin}
            href={process.env.REACT_APP_API_URL + ROUTES.GOOGLE_AUTH}
          >
            <img
              height="40px"
              width="40px"
              src={googleLogo}
              alt="google logo"
            />
            Login with Google
          </a>
        </div>
        <div className={styles.linksWrapper}>
          <button
            className={styles.signUpBtn}
            onClick={() => dispatch(setIsShowRegisterModalAC(true))}
          >
            Sign Up
          </button>
          <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password?</Link>
        </div>
      </div>
    </>
  );
};

export default LogInComponent;
