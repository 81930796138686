import styles from "./App.module.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/homePage/homePage";
import Forms from "./pages/forms/forms";
import SignUp from "./pages/auth/signup/signup";
import FormBuilder from "./pages/formBuilder/formBuilder";
import FormView from "./pages/formView/formView";
import ActivateUserPage from "./pages/auth/signup/activateUserPage/activateUserPage";
import LogIn from "./pages/auth/login/login";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import ResetPassword from "./pages/forgotPassword/resetPassword/resetPassword";
import GoogleLogin from "./pages/auth/googleLogin/googleLogin";
import { jwtDecode } from "jwt-decode";
import HowItWorks from "./pages/howItWorks/howItWorks";
import Pricing from "./pages/pricing/pricing";
import WhoWeServe from "./pages/whoWeServe/whoWeServe";
import AdminPage from "./pages/adminPage/adminPage";
import ROUTES from "./_utils/routes/routes";
import GeneralContracting from "./pages/whoWeServe/pages/generalContracting/generalContracting";
import SettingsPage from "./pages/settingPage/settingsPage";
import PaymentSuccess from "./pages/paymentSuccess/paymentSuccess";
import ChangeEmail from "./pages/changeEmail/changeEmail";
import LeadsPage from "./pages/leads/leadsPage";

function App() {
  const token = localStorage.getItem("token");

  if (token) {
    const decodedToken = jwtDecode(token);
    //@ts-ignore
    const expirationDate = decodedToken.exp * 1000;

    if (Date.now() >= expirationDate) {
      localStorage.removeItem("token");
    }
  }

  const hostname = window.location.hostname;
  const cleanedHostname = hostname.startsWith("www.")
    ? hostname.slice(4)
    : hostname;
  const subdomain = cleanedHostname.split(".")[0];

  if (subdomain === process.env.REACT_APP_DOMAIN?.split(".")[0]) {
    return (
      <div className={styles.wrapper}>
        <Routes>
          <Route path={ROUTES.HOME} element={<HomePage />} />

          <Route path={ROUTES.HOW_IT_WORKS} element={<HowItWorks />} />
          <Route path={ROUTES.WHO_WE_SERVE} element={<WhoWeServe />} />
          <Route path={ROUTES.PRICING} element={<Pricing />} />

          <Route path={ROUTES.FORM_BUILDER} element={<FormBuilder />} />
          <Route path={ROUTES.FORMS} element={<Forms />} />

          <Route path={ROUTES.SIGNUP} element={<SignUp />} />
          <Route path={ROUTES.LOGIN} element={<LogIn />} />

          <Route path={ROUTES.ACTIVATE_USER} element={<ActivateUserPage />} />

          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />

          <Route path={ROUTES.CHANGE_EMAIL} element={<ChangeEmail />} />

          <Route path={ROUTES.GOOGLE_AUTH} element={<GoogleLogin />} />

          <Route path={ROUTES.ADMIN} element={<AdminPage />} />

          <Route path={ROUTES.SETTINGS} element={<SettingsPage />} />

          <Route path={ROUTES.LEADS} element={<LeadsPage />} />

          <Route path={`${ROUTES.FORMS}/:id`} element={<FormView />} />

          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.GENERAL_CONTRACTING}`}
            element={<GeneralContracting />}
          />

          <Route
            path={`${ROUTES.PAYMENT_SUCCESS}`}
            element={<PaymentSuccess />}
          />

          <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
        </Routes>
      </div>
    );
  } else {
    return (
      <Routes>
        <Route path={ROUTES.HOME} element={<FormView />} />
        <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
      </Routes>
    );
  }
}

export default App;
