import React from "react";
import styles from "./answerItemButtonWithImage.module.scss";
import { IAnswerItemButtonWithImage } from "src/pages/interfaces/interfaces";
import imagePlaceholder from "src/assets/image.svg";

const AnswerItemButtonWithImage: React.FC<IAnswerItemButtonWithImage> = ({
  question,
  icon,
  handleOptionChange,
}: IAnswerItemButtonWithImage) => {
  return (
    <label className={styles.wrapper}>
      <input
        type="radio"
        id={question}
        value={question}
        onChange={handleOptionChange}
      />
      <img
        src={icon !== "" && icon !== undefined ? icon : imagePlaceholder}
        alt="question"
        className={styles.radioButton}
      />
      <p className={styles.question}>{question}</p>
    </label>
  );
};

export default AnswerItemButtonWithImage;
