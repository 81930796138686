import { IQuestion } from "src/pages/formBuilder/interfaces/interfaces";
import { IUserInfo } from "src/pages/formView/interfaces/interfaces";

export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_COMPANY_LOGO = "SET_COMPANY_LOGO";
export const CURRENT_CREATE_FORM_PAGE = "CURRENT_CREATE_FORM_PAGE";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const CURRENT_FORM_PAGE = "CURRENT_FORM_PAGE";
export const SET_USER_ANSWERS = "SET_USER_ANSWERS";
export const ADD_ANSWER_OPTION = "ADD_ANSWER_OPTION";
export const UPDATE_ANSWER_OPTION_TEXT = "UPDATE_ANSWER_OPTION_TEXT";
export const ADD_QUESTION_TO_ANSWER_OPTION = "ADD_QUESTION_TO_ANSWER_OPTION";
export const UPDATE_CONDITIONAL_QUESTION = "UPDATE_CONDITIONAL_QUESTION";
export const SET_CONTINUE_BUTTON_DISABLED = "SET_CONTINUE_BUTTON_DISABLED";
export const SET_IS_ANY_ANSWER_OPTIONS = "SET_IS_ANY_ANSWER_OPTIONS";
export const UPDATE_ANSWER_OPTION_ICON = "UPDATE_ANSWER_OPTION_ICON";
export const SET_IS_GOOGLE_AUTH_ERROR = "SET_IS_GOOGLE_AUTH_ERROR";
export const ADD_ANSWER_OPTION_TO_QUESTION = "ADD_ANSWER_OPTION_TO_QUESTION";
export const EDIT_TEXT_ANSWER_OPTION_TO_QUESTION =
  "EDIT_TEXT_ANSWER_OPTION_TO_QUESTION";
export const EDIT_ICON_ANSWER_OPTION_TO_QUESTION =
  "EDIT_ICON_ANSWER_OPTION_TO_QUESTION";
export const SET_ENDING_PAGE_TEXT = "SET_ENDING_PAGE_TEXT";
export const SET_USER_INFO = "SET_USER_INFO";
export const CLEAR_USER_ANSWERS = "CLEAR_USER_ANSWERS";
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_HOW_SHALL_WE_CONTACT_YOU_ERROR =
  "SET_HOW_SHALL_WE_CONTACT_YOU_ERROR";
export const OBSERVE_NEXT_PLAN_CHANGED = "OBSERVE_NEXT_PLAN_CHANGED";
export const OBSERVE_ADDITIONAL_PURCHASE_ADDED =
  "OBSERVE_ADDITIONAL_PURCHASE_ADDED";
export const SET_IS_USER_PLAN_ACTIVE = "SET_IS_USER_PLAN_ACTIVE";
export const SET_CURRENT_USER_PLAN = "SET_CURRENT_USER_PLAN";
export const SET_IS_CURRENT_USER_PLAN_LOADING =
  "SET_IS_CURRENT_USER_PLAN_LOADING";
export const SET_IS_SHOW_PRICING_MODAL = "SET_IS_SHOW_PRICING_MODAL";
export const SET_IS_SHOW_LOGIN_MODAL = "SET_IS_SHOW_LOGIN_MODAL";
export const SET_IS_SHOW_REGISTER_MODAL = "SET_IS_SHOW_REGISTER_MODAL";
export const SET_HSWCY_OTHER_INFO = "SET_HSWCY_OTHER_INFO";

export const setCompanyNameAC = (value: any) => ({
  type: SET_COMPANY_NAME,
  payload: value,
});

export const setCompanyLogoAC = (value: any) => ({
  type: SET_COMPANY_LOGO,
  payload: value,
});

export const setCurrentCreateFormPageAC = (value: number) => ({
  type: CURRENT_CREATE_FORM_PAGE,
  payload: value,
});

export const setCurrentFormPageAC = (value: number) => ({
  type: CURRENT_FORM_PAGE,
  payload: value,
});

export const setQuestionsAC = (payload: IQuestion[]) => ({
  type: SET_QUESTIONS,
  payload,
});

export const setUserAnswersAC = (answer: any, index: number) => ({
  type: SET_USER_ANSWERS,
  payload: { answer, index },
});

export const clearUserAnswersAC = () => ({
  type: CLEAR_USER_ANSWERS,
  payload: [],
});

export const addAnswerOptionAC = (questionIndex: any, answerText: any) => ({
  type: ADD_ANSWER_OPTION,
  payload: { questionIndex, answerText },
});

export const updateAnswerOptionTextAC = (
  questionIndex: any,
  optionIndex: any,
  newText: any
) => ({
  type: UPDATE_ANSWER_OPTION_TEXT,
  payload: { questionIndex, optionIndex, newText },
});

export const updateAnswerOptionIconAC = (
  questionIndex: number,
  optionIndex: number,
  newIcon: string,
  iconFile?: any
) => ({
  type: UPDATE_ANSWER_OPTION_ICON,
  payload: { questionIndex, optionIndex, newIcon, iconFile },
});

export const addQuestionToAnswerOptionAC = (
  questionIndex: any,
  answerOptionIndex: any,
  questionText: any
) => ({
  type: ADD_QUESTION_TO_ANSWER_OPTION,
  payload: { questionIndex, answerOptionIndex, questionText },
});

export const UpdateConditionalQuestionAC = (
  questionIndex: any,
  answerOptionIndex: any,
  questionText: any,
  newQuestionIndex: any
) => ({
  type: UPDATE_CONDITIONAL_QUESTION,
  payload: { questionIndex, answerOptionIndex, questionText, newQuestionIndex },
});

export const setContinueButtonDisabledAC = (value: boolean) => ({
  type: SET_CONTINUE_BUTTON_DISABLED,
  payload: value,
});

export const setIsAnyAnswerOptionsAC = (value: boolean) => ({
  type: SET_IS_ANY_ANSWER_OPTIONS,
  payload: value,
});

export const setGoogleAuthErrorAC = (value: boolean) => ({
  type: SET_IS_GOOGLE_AUTH_ERROR,
  payload: value,
});

export const addAnswerOptionToQuestionAC = (
  questionIndex: number,
  answerOptionIndex: number,
  questionArrayIndex: number
) => {
  return {
    type: ADD_ANSWER_OPTION_TO_QUESTION,
    payload: {
      questionIndex,
      answerOptionIndex,
      questionArrayIndex,
    },
  };
};

export const editTextAnswerOptionToQuestionAC = (
  questionIndex: number,
  answerOptionIndex: number,
  questionArrayIndex: number,
  optionIndex: number,
  title: string
) => {
  return {
    type: EDIT_TEXT_ANSWER_OPTION_TO_QUESTION,
    payload: {
      questionIndex,
      answerOptionIndex,
      questionArrayIndex,
      optionIndex,
      title,
    },
  };
};

export const editIconAnswerOptionToQuestionAC = (
  questionIndex: number,
  answerOptionIndex: number,
  questionArrayIndex: number,
  optionIndex: number,
  icon: any,
  iconFile?: any
) => {
  return {
    type: EDIT_ICON_ANSWER_OPTION_TO_QUESTION,
    payload: {
      questionIndex,
      answerOptionIndex,
      questionArrayIndex,
      optionIndex,
      icon,
      iconFile,
    },
  };
};

export const setEndingPageTextAC = (value: string) => {
  return {
    type: SET_ENDING_PAGE_TEXT,
    payload: value,
  };
};

export const setUserInfoAC = (value: IUserInfo) => {
  return {
    type: SET_USER_INFO,
    payload: value,
  };
};

export const setUserNameAC = (value: string) => {
  return {
    type: SET_USER_NAME,
    payload: value,
  };
};

export const setHowShallWeContactYouErrorAC = (value: boolean) => {
  return {
    type: SET_HOW_SHALL_WE_CONTACT_YOU_ERROR,
    payload: value,
  };
};

export const observeNextPlanChangedAC = (value: boolean) => {
  return {
    type: OBSERVE_NEXT_PLAN_CHANGED,
    payload: value,
  };
};

export const observeAdditionalPurchaseAddedAC = (value: boolean) => {
  return {
    type: OBSERVE_ADDITIONAL_PURCHASE_ADDED,
    payload: value,
  };
};

export const setIsUserPlanActiveAC = (value: boolean) => {
  return {
    type: SET_IS_USER_PLAN_ACTIVE,
    payload: value,
  };
};

export const setCurrentUserPlanAC = (value: boolean) => {
  return {
    type: SET_CURRENT_USER_PLAN,
    payload: value,
  };
};

export const setIsCurrentUserPlanLoadingAC = (value: boolean) => {
  return {
    type: SET_IS_CURRENT_USER_PLAN_LOADING,
    payload: value,
  };
};

export const setIsShowPricingModalAC = (value: boolean) => {
  return {
    type: SET_IS_SHOW_PRICING_MODAL,
    payload: value,
  };
};

export const setIsShowLoginModalAC = (value: boolean) => {
  return {
    type: SET_IS_SHOW_LOGIN_MODAL,
    payload: value,
  };
};

export const setIsShowRegisterModalAC = (value: boolean) => {
  return {
    type: SET_IS_SHOW_REGISTER_MODAL,
    payload: value,
  };
};

export const setHSWCYOtherInfoAC = (value: any) => {
  return {
    type: SET_HSWCY_OTHER_INFO,
    payload: value,
  };
};
