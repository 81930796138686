const ROUTES = {
  HOME: "/",
  SIGNUP: "/signup",
  ACTIVATE_USER: "/activate-user",
  LOGIN: "/login",
  GOOGLE_AUTH: "/auth/google",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_EMAIL: "/change-email",
  RESET_PASSWORD: "/reset-password",
  FORM_BUILDER: "/form-builder",
  FORM_VIEW: "/form-view",
  HOW_IT_WORKS: "/how-it-works",
  WHO_WE_SERVE: "/who-we-serve",
  PRICING: "/pricing",
  ADMIN: "/admin",
  FORMS: "/forms",
  GENERAL_CONTRACTING: "/general-contracting",
  SETTINGS: "/settings",
  PAYMENT_SUCCESS: "/success/*",
  LEADS: "/leads"
};

export default ROUTES;
