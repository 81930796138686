import React, { useEffect, useState } from "react";
import styles from "./howShallWeContactYou.module.scss";
import FormInput from "src/_elements/formInput/formInput";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfoAC, setUserNameAC } from "src/store/actions";
import { IUserInfo } from "../interfaces/interfaces";
import COUNTRY_CODES from "src/_utils/countryCodes";
import dropdown from "src/assets/dropdown.svg";

const HowShallWeContactYou = () => {
  const dispatch = useDispatch();

  const isGlobalError = useSelector(
    (state: any) => state.howShallWeContactYou.isGlobalError
  );

  const [selectedCountry, setSelectedCountry] = useState(
    COUNTRY_CODES.find((obj) => obj.country === "US") ?? COUNTRY_CODES[0]
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(selectedCountry.code);
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [address, setAddress] = useState("");
  const [zipOrPostal, setZipOrPostal] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const userInfo: IUserInfo = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      date: date,
      time: time,
      address: address,
      zipOrPostal: zipOrPostal,
    };

    dispatch(setUserNameAC(firstName + " " + lastName));
    dispatch(setUserInfoAC(userInfo));
  }, [
    firstName,
    lastName,
    phoneNumber,
    email,
    date,
    time,
    address,
    zipOrPostal,
  ]);

  const handleCountryChange = (country: any) => {
    setIsDropdownOpen(false);
    setSelectedCountry(country);
    setPhoneNumber(country.code);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handlePhoneNumberChange = (e: any) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.startsWith(selectedCountry.code.replace("+", ""))) {
      setPhoneNumber(`+${numericValue}`);
    } else {
      setPhoneNumber(
        `${selectedCountry.code}${numericValue.slice(
          selectedCountry.code.length
        )}`
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.questionTitle}>How shall we contact you?</p>
      {isGlobalError && (
        <p className={styles.errorMessage}>
          Please fill in all fields marked with *
        </p>
      )}
      <div className={styles.nameSection}>
        <div>
          <p className={styles.inputLabel}>*First name:</p>
          <FormInput
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First name"
          />
        </div>
        <div>
          <p className={styles.inputLabel}>*Last name:</p>
          <FormInput
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last name"
          />
        </div>
      </div>
      <p className={styles.inputLabel}>*Phone number:</p>
      <div className={styles.phoneNumberInputWrapper}>
        <div className={styles.customSelect} onClick={toggleDropdown}>
          <div className={styles.selectedOption}>
            <img src={selectedCountry.flag} alt={selectedCountry.code} />
            <img className={styles.dropdown} src={dropdown} alt="arrow" />
          </div>
          {isDropdownOpen && (
            <div className={styles.options}>
              {COUNTRY_CODES.map((country, index) => (
                <div
                  key={index}
                  className={styles.option}
                  onClick={() => handleCountryChange(country)}
                >
                  <p>{country.country}</p>
                  <img src={country.flag} alt={country.code} />
                </div>
              ))}
            </div>
          )}
        </div>
        <FormInput
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          type="tel"
          placeholder="Phone number"
        />
      </div>
      <p className={styles.inputLabel}>*Email:</p>
      <FormInput
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <br />
      <div className={styles.otherInfo}>
        <div>
          <p className={styles.inputLabel}>Date:</p>
          <FormInput
            value={date}
            onChange={(e) => setDate(e.target.value)}
            type="date"
            placeholder="Date"
          />
        </div>
        <div>
          <p className={styles.inputLabel}>Time:</p>
          <FormInput
            value={time}
            onChange={(e) => setTime(e.target.value)}
            type="time"
            placeholder="Time"
          />
        </div>
        <div>
          <p className={styles.inputLabel}>Address:</p>
          <FormInput
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Address"
          />
        </div>
        <div>
          <p className={styles.inputLabel}>ZIP/Postal code:</p>
          <FormInput
            value={zipOrPostal}
            onChange={(e) => setZipOrPostal(e.target.value)}
            placeholder="ZIP/Postal code"
          />
        </div>
      </div>
    </div>
  );
};

export default HowShallWeContactYou;
